<top-notification [message]="notificationMsg" [isSuccess]="isSuccess" *ngIf="showTopNotification"></top-notification>

<section class="login-section" *ngIf="localState.loginSection ">
  <app-loading *ngIf="loading"></app-loading>

  <div id="login-header-title">
    <!-- text-color will be controlled by theme.-->
    <h1 class='app-name text-color themed-text' *ngIf="!localState.clientConfig">Log in to your Taylor & Francis account </h1>
    <h1 class='app-name text-color themed-text' *ngIf="localState.clientConfig?.name && !localState.hideAppName">{{ localState.clientConfig.name }}</h1>
    <p class="app-title" [ngClass]="[localState.hideAppName ? 'app-title-padding' : '']" *ngIf="localState.clientConfig?.title">{{ localState.clientConfig.title }}</p> 
  </div>

  <div class="login-wrapper" [ngClass]="[!localState.clientConfig ? 'login-wrapper-no-config' : '']">
    <input id="toggle" type="checkbox" class="hideToggle" *ngIf="!localState.loginSection">
    <label class="toggle-label hideToggle" for="toggle" *ngIf="!localState.loginSection"></label>
    <div class="login-inner-wrapper">
      <div id="with-email" class="tab-content" [ngClass]="[localState.clientConfig ? 'tab-content-config' : '']">
        <h2 class='with-email-label' for="toggle" *ngIf="!localState.clientConfig">With email</h2>
        <div class="tab-pane text-center fade active in login-form-title" *ngIf="localState.clientConfig && localState.login_window_title">
          <ng-container>Login to continue</ng-container>
        </div>
        <div class="text-center login-title-sub-flow-text"
          *ngIf="localState.clientConfig && localState.clientConfig.subFlowText && localState.clientConfig.subFlowJournal">
          <div>
            {{ localState.clientConfig.subFlowText }}
            <button id="journalLink" type="reset" class="links forgot-pwd" (click)="onClickRedirectToHomelink()">{{ localState.clientConfig.subFlowJournal }}</button>
          </div>
        </div>
        <div class="tab-pane text-center fade active in">
          <span class="error-msg" *ngIf="localState.isUnexpectedError">{{ localState.errorMessage }}</span>
          <form class="form-bowl" (ngSubmit)="login()" autocomplete="off">
            <div [ngClass]="{'has-error': (localState.isInvalidEmail),'is-empty': !(localState.email)}"
              class="form-group is-empty label-floating">
              <span for="inputEmail" class="form-label">Email</span>
              <input type="text" class="form-control" [(ngModel)]="localState.email" name="email"
                (input)="validateEmail()" (blur)="validateEmailBlur()"
                [attr.aria-describedby]="invalidLoginEntry()?'invalidMailError':'invalidMailValue'"
                (ngModelChange)="redirectToOffice365IfInformaDomain(localState.email)" id="inputEmail"
                [ngClass]="{'inputError':localState.isInvalidEmail}">

              <p id="invalidMailValue" hidden>{{ localState.errorMessage }}</p>
              <span class="input-error" [ngClass]="localState.isInvalidEmail===true?'class1':'class2'"
                id="invalidMailError">
                <span class="icon-error"> </span> <span class="error-position">{{ localState.errorMessage }}</span>
              </span>
              
            </div>
            <div [ngClass]="{'has-error': (localState.isIncorrectPassword),'is-empty': !(localState.password)}"
              class="form-group is-empty label-floating password">
              <span for="inputPassword" class="form-label">Password</span>
                  <input [disabled]="isOffice365Login" [type]=" viewPswd == 'icon-eye-blocked' ? 'password': 'text'"
                    class="form-control"
                    [(ngModel)]="localState.password" name="password" (blur)="validatePasswordBlur()"
                    (input)="validatePassword()" id="inputPassword"
                    [attr.aria-describedby]="invalidPasswordEntry()?'passwordError':'invalidPassValue'"
                    [ngClass]="{'inputError': isIncorrectPassword}"
                    [ngClass]="{'inputError':localState.isInvalidPassword || localState.isIncorrectPassword || localState.isIncorrectEmail || blurPasswordError}" />
              <span class="pwd-view-icon" 
                (click)= "(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
                [ngClass]="viewPswd">
              </span>
              <p id="invalidPassValue" [innerHTML]="localState.errorMessagePassword" hidden></p>
            </div>
            <span class="input-error pwd-error"
                *ngIf="localState.isInvalidPassword || localState.isIncorrectPassword || localState.isIncorrectEmail || blurPasswordError || localState.isUserBlocked">
                <span class="icon-error"
                  [ngClass]="{'credentialErrorIcon':localState.isInvalidPassword || localState.isIncorrectEmail|| localState.isUserBlocked}">
                </span>
                <span id="passwordError" [innerHTML]="localState.errorMessagePassword" class="error-position"></span>
            </span>
            <div class="form-group users-help-box">
              <a class="pull-right links forgot-pwd" href="javascript:;" (click)="forgotPassword(localState)">Forgot
                password?</a>
            </div>
            <!-- #loginBtn is styled based on theme -->
            <div class="form-button">
              <button color="primary" class="themed-button btn btn-primary btn-block" id="loginBtn"
                #loginButton>{{isOffice365Login? 'Login with Informa SSO': 'Log In'}}</button>
            </div>
          </form>
        </div>
        <div id="signupWrapper" class="text-color themed-text" *ngIf="localState.clientConfig?.loginConfig?.signup_taylor_francis_account==undefined || localState.clientConfig?.loginConfig?.signup_taylor_francis_account">
          <a class="sign-up links text-color themed-text" href="javascript:;"
            aria-label="Don’t have an account yet? Sign up" (click)="navigateToSignUp()">Create a Taylor & Francis
            account</a>
        </div>
        <div class="orcid-divider-section form-bowl" *ngIf="localState.clientConfig?.loginConfig?.orcid || 
            localState.clientConfig?.loginConfig?.shibboleth_openathens">
          <div class="divider orcid-divider"></div>
          <div>&nbsp;Or&nbsp;</div>
          <div class="divider orcid-divider"></div>
        </div>
        <div class="form-button form-bowl" *ngIf="localState.clientConfig?.loginConfig?.orcid">
          <button color="primary" class="btn btn-orcid btn-block" id="orcidBtn" #orcidButton
            (keydown.enter)="orcidLogin()" (click)="orcidLogin()">
            <img class="orcid-btn-image" src="assets/img/orcid.png" alt="orcid button">Log in with ORCID</button>
          <div class="text-center account-create-orcid">
            Don't have an ORCID iD yet? <a href="javascript:;" (click)="orcidSignup()">Create one now</a>
          </div>
        </div>
        <div class="form-button form-bowl" *ngIf="localState.clientConfig?.loginConfig?.shibboleth_openathens">
          <button color="primary" class="btn btn-orcid btn-block" id="shibbolethLogin" (keydown.enter)="orgList()" (click)="orgList()">Log in with Shibboleth or OpenAthens</button>
        </div>
        <div class="social-networks">
          <div class="login-info">or connect with</div>
          <div class="network-detail">
            <form ngNoForm action="{{localState.facebook}}" method="get" class="ng-pristine ng-valid">
              <input id="facebook" aria-label="Facebook" type="submit" />
              <input type="hidden" name="clientId" [value]="localState.clientId" />
            </form>
          </div>
          <div class="network-detail">
            <form ngNoForm action="{{localState.linkedin}}" method="get" class="ng-pristine ng-valid">
              <input id="linkedin" aria-label="Linkedin" type="submit" />
              <input type="hidden" name="clientId" [value]="localState.clientId" />
            </form>
          </div>
          <div class="network-detail">
            <form ngNoForm action="{{localState.google}}" method="get" class="ng-pristine ng-valid">
              <input id="google" aria-label="Google" type="submit" />
              <input type="hidden" name="clientId" [value]="localState.clientId" />
            </form>
          </div>
        </div>
      </div>

      <div class="client-description" *ngIf="localState.clientConfig?.description">
        <app-login-description></app-login-description>
      </div>
      <div class="inst-login" *ngIf="!localState.clientConfig">
        <!-- show Login option based on Configuration below -->
        <div class="shib-login align-elements" id="shibbolethLogin" tabindex="0" (keydown.enter)="orgList()"
          (click)="orgList()">
          <div>
            <h2 class="inst-msgs">
              With Shibboleth or OpenAthens
            </h2>
            <div class="research-msg">
              to access research made available through your institution
            </div>

          </div>

          <div class="themed-circle login-right-arrow">
            <em class="material-icons themed-arrow right-arrow-icon-img">
              chevron_right
            </em>
          </div>

        </div>
        <div class="divider"></div>
        <!-- show Login option based on Configuration below -->
        <div class="orcid-login align-elements" tabindex="0" (keydown.enter)="orcidLogin()" (click)="orcidLogin()">
          <div>
            <h2 class="inst-msgs orcid">
              <span>With</span><img src="assets/img/new-orcid.png" alt="orcid">
            </h2>

            <div class="research-msg">
              to submit your research for publication and access your dashboard
            </div>
          </div>

          <div class="themed-circle login-right-arrow">
            <em class="material-icons themed-arrow right-arrow-icon-img">
              chevron_right
            </em>
          </div>

        </div>
      </div>
    </div>
  </div>


</section>
<section class="shib-section" *ngIf="localState.shibSection && (localState.orgs && localState.orgs.length > 0)">

  <div class="institution-login-wrapper">
    <h1 class="institution-msg">
      Institution log in
    </h1>
    <div (click)="showLogin()" (keydown.enter)="showLogin()" class="backtoLogin" tabindex="0" id="backLogin"
      role="link">
      <div class="login-path"> </div>
      <span class="backLink">Back</span>
    </div>
    <p aria-labelledby="backLogin" hidden>Click here to go to Login Page</p>
    <div class="shib-oathens-login">
      Log In with Shibboleth or OpenAthens
    </div>
    <div class="search-institute-wrapper">
      <div aria-expanded="false" autocomplete="off">
        <label for="searchInstitute" hidden>Institution login</label>
        <ng-autocomplete #ngAutoComplete placeholder="Search for your institution" [data]="localState.orgs"
          historyIdentifier="recentlySelectedOrgs" [searchKeyword]="'value'" (selected)='onSelectInstitute($event)'
          (inputCleared)='inputClearedFun($event)' (inputChanged)='onChangeFun($event)'
          [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.value"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>

    <form class="form-bowl" (ngSubmit)="samlLogin()">
      <div class="form-button continue-btn">
        <button type="submit" color="primary" class="themed-button btn btn-primary" id="cont-btn" [disabled]="disableContinueButton">Continue</button>
      </div>
    </form>
  </div>
</section>
<top-banner [message]="updateInfoMessage" [isSuccess]="false" *ngIf="showUdateInfoMessage"></top-banner>
