import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppState } from '../../../app.service';
import { AccountsService } from '../../../services/accounts-api/accounts-api.service';
import { ResetPasswordService } from '../../reset-password/reset-password.service';
@Component({
  selector: 'app-remove-account',
  templateUrl: './remove-account.component.html',
  styleUrls: ['./remove-account.component.scss'],
  providers: [ResetPasswordService],
})
export class RemoveAccountComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  user: any;
  username: any;
  showUdateInfoMessage: boolean;
  errorMessage: any;
  isErrorCurrentPwd = false;
  password = '';
  updateInfoMessage: string = '';
  isMarkAsDeleted: boolean = false;
  constructor(private accountsService: AccountsService, public appState: AppState, private _route: Router) {
  }
  ngOnInit() {
    this.subscription = this.appState.user$.subscribe((user) => {
      this.username = user.username;
      this.isMarkAsDeleted = user.markedForDeletion;
    });
  }

  markForDeletion(): void {
    this.accountsService.verifyPassword(this.username, this.password)
      .subscribe(res => {
        this.accountsService.markForDeletion(this.username)
          .subscribe(
            (user) => {
              this.password = '';
              this.showUdateInfoMessage = true;
              this.updateInfoMessage = "Your account removal request has been accepted and you will receive a confirmation email when it is complete. You will be logged out now";
              setTimeout(() => {
                this.logoutUser();
              }, 3000)
            },
            (err) => {
              console.log('err markForDeletion: ', err);
              this.errorMessage = err.metadata.message.value
            })
      }, (error) => {
        this.errorMessage = error.metadata.message.value;
        this.isErrorCurrentPwd = true;
      })
  }

  onInputChange() {
    this.isErrorCurrentPwd = false;
  }

  logoutUser() {
    this.appState.getTokenAndlogoutUser()
      .then(async () => {
        await this._route.navigate(['/login']);
      }).catch((err) => {
        console.log('error while logged out the user', err);
      })
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
