import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConfig } from '../../../url-config';

@Injectable()
export class ResetPasswordService {
	private baseURL: any = new UrlConfig().getBaseApiUrl();

	private forgotPwdURL = this.baseURL + "user/auth/forgotpassword";

	constructor(private http: HttpClient) { }

	forgotPassword(username: string, clientId: string): Observable<any> {
		let url = this.forgotPwdURL + '?username=' + encodeURIComponent(username);
		if(clientId) { url = url + '&client_id=' + clientId }
		return this.http.get(url);
	}

	verifyResetPasswordToken(username: string, resetPasswordToken: string): Observable<any> {
		return this.http.post(this.forgotPwdURL,
			{
				username: encodeURIComponent(username),
				resetPasswordToken: resetPasswordToken
			});
	}

	resetPassword(username: string, newPassword: string, resetPasswordToken: string): Observable<any> {
		return this.http.put(this.forgotPwdURL,
			{
				username: encodeURIComponent(username),
				newPassword: newPassword,
				resetPasswordToken: resetPasswordToken
			});
	}
}
