<mat-dialog-content class="dialog-content">

  <div class="dialog-title">
    <p class="cstm-dialog-title"><strong>We’ve noticed that you are eligible to use WeChat.</strong></p>
    <button class="cstm-icon-btn" [mat-dialog-close]="false"><em class="icon-cancel"></em></button>
  </div>
  <p class="cstm-dialog-description">To receive submission status updates via the WeChat app, please register on the
    Taylor & Francis Submission Portal
    with an email and password. We are unable to provide this service if you continue with ORCID.</p>
</mat-dialog-content>
<mat-dialog-actions align="left" class="dialog-action">

  <button mat-raised-button cdkFocusInitial [mat-dialog-close]="true" color="primary">REGISTER NOW</button>
  <button mat-raised-button mat-stroked-button [mat-dialog-close]="false">CONTINUE WITH ORCID</button>
</mat-dialog-actions>
