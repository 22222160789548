// src/environments/environment.prod.ts

// ENVIRONMENT FILE FOR PROD
//
// This file will be copied on top of `./environment.ts` for the environment called `prod`.
// It is associated with that environment by the setting in `.angular-cli.json`.

export const environment = {
  production: true,
  envName: "prod",

  // This key is used for reporting errors to sentry.io.
  //sentryDsn: "https://09d5e1b62a7b4ac98387aaae758a4b2e@sentry.io/229875"
  sentryDsn: undefined
};
