<section class="shib-section">
    <app-loading *ngIf="loading"></app-loading>
    <div class="search-institute-wrapper">
        <div class="institution-login-wrapper">
            <h1 class="institution-msg">
                Institution log in
            </h1>
            <div *ngIf="!errorMessage">
                <div class="shib-oathens-login">
                    Log In with Shibboleth or OpenAthens
                </div>
                <div class="search-institute-wrapper" aria-expanded="false" autocomplete="off">
                    <label for="searchInstitute" hidden>Institution login</label>
                    <ng-autocomplete #ngAutoComplete placeholder="Search for your institution" [data]="orgList"
                        [searchKeyword]="'value'"
                        (selected)="onSelectInstitute($event)" (inputCleared)="disableContinueButton = true"
                        (inputChanged)="disableContinueButton = true" [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.value"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <form class="form-bowl" (ngSubmit)="samlLogin()">
                    <div class="form-button continue-btn">
                        <button type="submit" color="primary" class="themed-button btn btn-primary" id="cont-btn"
                            [disabled]="disableContinueButton">Continue</button>
                    </div>
                </form>
            </div>
            <h3 *ngIf="errorMessage">{{errorMessage}}</h3>
        </div>
    </div>
</section>