
 export class NotificationVailidityChecker {
  public static compareDate(startDate: Date, endDate: Date): boolean {
    console.log(startDate, endDate);
    let currentDateTime = new Date();
    console.log("currentDateTime", currentDateTime);
    let currentDateTimeinUTC = currentDateTime.toUTCString();
    console.log("currentDateTimeinUTC", currentDateTimeinUTC);

    // Creating a custom Date object from current time in UTC for comparison
    let processingDate = new Date(new Date(currentDateTimeinUTC).getUTCFullYear(),
     new Date(currentDateTimeinUTC).getUTCMonth(), new Date(currentDateTimeinUTC).getUTCDate(),
     new Date(currentDateTimeinUTC).getUTCHours(), new Date(currentDateTimeinUTC).getUTCMinutes(), new Date(currentDateTimeinUTC).getUTCSeconds());
    console.log("processingDate", processingDate);
    if (processingDate >= startDate && processingDate <= endDate) {
      console.log("TRUE");
      return true;
    } else {
      console.log("FALSE")
      return false;
    }
  }
}
