import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConfig } from '../../../url-config';


@Injectable()

export class ConsentService {
	private baseURL: any = new UrlConfig().getBaseApiUrl();
	private loginURL = this.baseURL + "user/auth/login";
	private signUpURL = this.baseURL + "user/auth/signUp"

	constructor(private http: HttpClient) {

	}

	login(username: string, password: string): Observable<any> {
		return this.http.post(this.loginURL,
			{
				username: username,
				password: password
			});
	}

	register(username: string, password: string, fullName: string, institution: string): Observable<any> {
		return this.http.post(this.signUpURL,
			{
				username: username,
				password: password,
				fullName: fullName,
				organization: institution
			});
	}

}
