// app.routes.ts
//
// Route definitions for identity-ui.
// Consumed in `app.module`.

// ANGULAR
import { Routes } from '@angular/router';

// Routable components.
import { AcceptTermsComponent } from './components/ui/accept-terms/accept-terms.component';
import { MFAComponent } from './components/ui/multi-factor-auth/multi-factor-auth.component';
import { AccessDeniedComponent } from './components/pages/access-denied/access-denied.component';
import { ConsentComponent } from './modules/consent/consent.component';
import { LoginRegisterComponent } from './modules/login-register/login-register.component';
import { NoContentComponent } from './components/pages/no-content/no-content.component';
import { OrcidShibbolethComponent } from './modules/orcid-shibboleth/orcid-shibboleth.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { VerifyRegistrationComponent } from './modules/verify-registration/verify-registration.component';

// ACCOUNT
import { AccountComponent } from './modules/account/account.component';
import { MyAccountComponent } from './modules/account/my-account/my-account.component';
import { EditPasswordComponent } from './modules/account/edit-password/edit-password.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { RemoveAccountComponent } from './modules/account/remove-account/remove-account.component';
import { UpdateEmailComponent } from './modules/account/update-email/update-email.component';
import { WayflessOrgComponent } from './components/pages/wayfless-org/wayfless-org.component';
export const ROUTES: Routes = [
  // Default route.
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  // Primary routes.
  {path: 'login',          component: LoginRegisterComponent},
  {path: 'consent',        component: ConsentComponent},
  {path: 'verify',         component: VerifyRegistrationComponent},
  {path: 'verify-registration', component: VerifyRegistrationComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'sign-up',        component: SignUpComponent},
  {path: 'orcid-shibboleth',    component: OrcidShibbolethComponent},
  {path: 'accept-terms',   component: AcceptTermsComponent},
  {path: 'multi-factor-auth',   component: MFAComponent},
  {path: 'access-denied',  component: AccessDeniedComponent},
  {path: 'wayfless-org',  component: WayflessOrgComponent},

  // Account page, with subpages.
  {
    path: 'accounts', component: AccountComponent,
    children: [
      { path: '', redirectTo: 'your-account', pathMatch: 'full' },
      { path: 'my-account', redirectTo: 'your-account', pathMatch: 'full' },
      { path: 'your-account', component: MyAccountComponent },
      { path: 'update-email', component: UpdateEmailComponent },
      // removing link-account untill linking of account is completed
      // { path: 'link-account', component: LinkAccountComponent },
      { path: 'edit-password', component: EditPasswordComponent },
      { path: 'remove-account', component: RemoveAccountComponent }
    ]
  },
  // Aliases.
  //{path: 'verify-registration/', redirectTo: '/verify', pathMatch: 'full'},

  // Catch routes
  { path: '404', component: NoContentComponent },
  { path: '**', redirectTo: '/404' }
];
