// src/url-config.ts
//
// Provide URLs for API endpoints based on environment.
//
// requiring the class to be instantiated each and every time it is accessed,
// to a plain old function.
// Doing this will require changing several dozen places from which it is accessed.
//
// The environment "local" may be used to access an instance of auth-api running
// on `localhost` at port 3000.

import { environment } from './environments/environment';

export class UrlConfig {
  env = environment.envName;

  getBaseApiUrl() {
    switch (this.env) {
      case "dev": return "https://api-dev.taylorfrancis.com/v2/auth/";
      case "uat": return "https://api-uat.taylorfrancis.com/v2/auth/";
      case "prod": return "https://api.taylorfrancis.com/v2/auth/";
      case "local": return "http://localhost:3000/auth/";
      default: return "";
    }
  }

  getBaseUrl() {
    switch (this.env) {
      case "dev": return "https://api-dev.taylorfrancis.com/v2";
      case "uat": return "https://api-uat.taylorfrancis.com/v2";
      case "prod": return "https://api.taylorfrancis.com/v2";
      case "local": return "http://localhost:3000";
      default: return "";
    }
  }

  getSPClientId() {
    switch (this.env) {
      case "dev": return "19d999672d218ec77c1b04c0a5f8b3427145c5e850ab3c13ce8eb2ad2ec89287";
      case "uat": return "19d999672d218ec77c1b04c0a5f8b3427145c5e850ab3c13ce8eb2ad2ec89287";
      case "prod": return "59f21242bb410562f60413514f5108d80ede3086581e834d9027687f7a875502";
      case "local": return "19d999672d218ec77c1b04c0a5f8b3427145c5e850ab3c13ce8eb2ad2ec89287";
      default: return "";
    }
  }

  getdefaultClientConfig() {
    if (this.env === "dev") {
      return JSON.stringify({
        "name": "Identity Website",
        "hideAppName":false,
        "redirect_uris": ["https://dev.taylorfrancis.com/callback"],
        "baseUrl": "https://accounts-dev.taylorfrancis.com/identity/#/login",
        "description": "<h3><strong>Login or Register</strong></h3><p>&nbsp; Login or register to access our client applications</p>",
        "loginConfig": {
          "username_password": true,
          "orcid": true,
          "shibboleth_openathens": false,
          "signup_taylor_francis_account":true,
          "login_window_title":true
        },
        "title": "Default Login application"
      });
    } else {
      return JSON.stringify({
        "name": "Identity Website",
        "hideAppName":false,
        "redirect_uris": this.env === 'prod' ? ["https://taylorfrancis.com/callback"] : ["https://uat.taylorfrancis.com/callback"],
        "baseUrl": this.env === 'prod' ? "https://accounts.taylorfrancis.com/identity/#/login" : "https://accounts-" + this.env + ".taylorfrancis.com/identity/#/login",
        "description": "<h3><strong>Login or Register</strong></h3><p><br>Login or register to access our client applications</p>",
        "loginConfig": {
          "username_password": true,
          "orcid": true,
          "shibboleth_openathens": false,
          "signup_taylor_francis_account":true,
          "login_window_title":true
        },
        "title": "Default Login application"
      });
    }
  }

  getApplicationBaseUrl(brand: string) {
    switch (this.env) {
      case "dev":
        return this.getAppBaseDev(brand);
      case "uat":
        return this.getAppBaseUat(brand);
      case "prod":
        return this.getAppBaseProd(brand);
      case "local": return "http://localhost:4200";
      default: return "";
    }
  }

  private getAppBaseDev(brand: string) {
    let baseUrl: string;
    if (brand === 'ubx') {
      baseUrl = "https://dev.taylorfrancis.com";
    } else if (brand === 'rptnf' || brand === 'rpcogent' || brand === 'cogent') {
      baseUrl = "https://rp-tandfonline-dev.uk.corplan.net";
    } else {
      baseUrl = "https://dev.taylorfrancis.com";
    }
    return baseUrl;
  }

  private getAppBaseUat(brand: string) {
    let baseUrl: string;
    if (brand === 'ubx') {
      baseUrl = "https://uat.taylorfrancis.com";
    } else if (brand === 'rptnf' || brand === 'rpcogent' || brand === 'cogent') {
      baseUrl = "https://rp-tandfonline-uat.uk.corplan.net";
    } else {
      baseUrl = "https://uat.taylorfrancis.com";
    }
    return baseUrl;
  }

  private getAppBaseProd(brand: string) {
    let baseUrl: string;
    if (brand === 'ubx') {
      baseUrl = "https://taylorfrancis.com";
    } else if (brand === 'rptnf' || brand === 'rpcogent' || brand === 'cogent') {
      baseUrl = "https://rp-tandfonline.com";
    } else {
      baseUrl = "https://taylorfrancis.com";
    }
    return baseUrl;
  }

  getCountriesUrl() {
    switch (this.env) {
      case "dev": return "https://api-dev.taylorandfrancis.com/v2/geolocation/countries/all";
      case "uat": return "https://api-uat.taylorandfrancis.com/v2/geolocation/countries/all";
      case "prod": return "https://api.taylorandfrancis.com/v2/geolocation/countries/all";
      case "local": return "https://api-dev.taylorandfrancis.com/v2/geolocation/countries/all";
      default: return "";
    }
  }

  getClientConfig(client: string) {
    switch (client) {
      case "ubx": return {
        termsOfUseLink: 'https://www.taylorfrancis.com/terms-and-conditions',
        privacyPolicy: 'https://informa.com/privacy-policy'
      };
      case "cogent": return {
        termsOfUseLink: 'https://s3-euw1-ap-pe-ws5-rp.cogentoa.s3-website-eu-west-1.amazonaws.com/terms-of-use.pdf'
      };
      default: return {
        termsOfUseLink: 'https://www.taylorfrancis.com/terms-and-conditions'
      };
    }
  }

  getOrcidVisibilitySettingUrl() {
    return "https://support.orcid.org/hc/en-us/articles/360006897614";
  }

  getOrcidSignInUrl() {
    if (this.env === "prod") return "https://orcid.org";
    return "https://sandbox.orcid.org";
  }

}
