import { Component, ViewEncapsulation, HostBinding } from '@angular/core';

import { AppState } from '../../app.service';
import { Router } from "@angular/router";
import { PrivilegesService } from '@tandfgroup/platform-component-common-privileges-service';

@Component({
  selector: 'accounts',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.scss']
})

export class AccountComponent {

  @HostBinding('class.account-wrapper') public wrapper = true;
  currentRoute: string = 'your-account';
  user: any = {};

  idToken = null;
  showAccountsSidePanel = false;

  constructor(
    public appState: AppState,
    private _route: Router, private privilegesService: PrivilegesService) { }

  ngOnInit() {
    // Adding setTimeOut method to wait till appState's user object is set.
    // This is to fix the issue of login page showing up while navigating to account page.

    setTimeout(() => {
      (async () => {
        await this.init();
      })();
    }, 3000);
  }

  async init() {
    document.getElementById('tab1').focus();

    if (Object.keys(this.appState.get('user')).length === 0) {
      await this._route.navigate(["/login"]);
    } else {
      this.user = this.appState.get('user');
    }
  }

  setActiveTab(route: string) {
    this.showAccountsSidePanel = false;
    this.currentRoute = route;
  }

  async tabKey(event: any) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      await this.routeToAccountsTab(event);
    }

    if (event.keyCode === 38 || event.keyCode === 40) {
      this.focusOnAccountsTab(event);
    }
  }

  private focusOnAccountsTab(event: any) {
    if (event.srcElement.id === "tab1") {
      event.srcElement.nextElementSibling.focus();
    }
    if (event.srcElement.id === "tab2") {
      event.srcElement.previousElementSibling.focus();
    }
  }

  public async routeToAccountsTab(event: any) {
    if (event.srcElement.id === "tab1") {
      this.setActiveTab('your-account');
      await this._route.navigateByUrl('/accounts/' + this.currentRoute);
    }
    if (event.srcElement.id === "tab2") {
      this.setActiveTab('edit-password');
      await this._route.navigateByUrl('/accounts/' + this.currentRoute);
    }
    if (event.srcElement.id === "tab3") {
      this.setActiveTab('remove-account');
      await this._route.navigateByUrl('/accounts/' + this.currentRoute);
    }
  }
}

