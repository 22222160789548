import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConfig } from '../../../url-config';
@Injectable()
export class LoginRegisterService {

  private baseURL: any = new UrlConfig().getBaseApiUrl();

  private loginURL = this.baseURL + "user/auth/login";
  private authURL = this.baseURL + "user/auth/oahomelink";

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string, isRememberMe: boolean, clientId: string, state: string): Observable<any> {

    let loginPayload = {
      username,
      password,
      isRememberMe
    }
    if (clientId && clientId !== "undefined" && clientId !== "null") {
      loginPayload["clientId"] = clientId;
    }
    if (state && state !== "undefined" && state !== "null") {
      loginPayload["state"] = state;
    }

    return this.http.post(this.loginURL, loginPayload);
  }

  redirectToOaHomelink(): string {
    return this.authURL;
  }
}
