import { Component, OnInit } from "@angular/core";
import { sortBy } from "lodash-es";
import { AppState } from "../../../app.service";
import { UrlConfig } from "../../../../url-config";
import { AuthapiService } from "../../../services/authapi/authapi.service";

@Component({
  selector: "app-wayfless-org",
  templateUrl: "./wayfless-org.component.html",
  styleUrls: ["./wayfless-org.component.scss"],
})
export class WayflessOrgComponent implements OnInit {
  orgList: { id: string; name?: string }[];
  selectedOrg: any;
  disableContinueButton = true;
  loading = true;
  errorMessage = ''

  constructor(
    private authapiService: AuthapiService,
    private appState: AppState
  ) {}

  ngOnInit() {
    this.authapiService.getOrgList(this.appState.get("queryParams")["client_id"], this.appState.get("queryParams")["entityId"], "true", "small").subscribe(
      (orgList) => {
        if (orgList) {
          // sort orgs albhabetically ascending
          this.orgList = sortBy(
            orgList.map((org: any) => {
              // name for org could be missing. show id if name not found.
              return { id: org._id, value: org.name || org._id ,};
            }),
            "value"
          );
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.errorMessage = err.metadata?.message || err.message || "Unable to process your request.";
      }
    );
  }

  onSelectInstitute(data: any) {
    if (data) {
      this.disableContinueButton = false;
      this.selectedOrg = this.orgList.find((org) => {
        return org.id === data.id || org.name === data.id;
      });
    } else {
      this.disableContinueButton = true;
    }
  }

  samlLogin(): void {
    if (this.selectedOrg?.id) {
      const clientId = this.appState.get("queryParams")["client_id"];
      const authorizeAction = new UrlConfig().getBaseApiUrl() + 'user/auth/authorize'
      const responseType = this.appState.get('queryParams')['response_type'];
      const scope = this.appState.get('queryParams')['scope'];
      const redirectUri = this.appState.get('queryParams')['redirect_uri'];
      const state = this.appState.get('queryParams')['state'];
      const orgMongoId = this.selectedOrg.id;
      const targetUrl = encodeURI(authorizeAction + '?response_type=' + responseType + '&client_id=' + clientId + '&scope=' + scope + '&saml_org_id=' + orgMongoId + '&redirect_uri=' + redirectUri + '&state=' + state);
      this.setHref(targetUrl);
    }
  }

  setHref(url: string) {
    window.location.href = url;
  }
}
