<div class="my-account">
  <app-loading *ngIf="loading"></app-loading>

  <div class="account-content" role="region" aria-label="Manage Your email">

    <div class="account-header-wrapper">
      <div class="account-header">
        <h1>Email</h1>
      </div>
    </div>

    <div class="account-body-wrapper">
      <p><strong>Current email</strong></p>
      <p class="curr-email">Your current email address is <strong>{{email}}</strong></p>
      <form *ngIf="!user.updateEmail" class="form-bowl" (ngSubmit)="updateEmail()">

        <div [ngClass]="{'has-error': (isInvalidEmail), 'is-empty': !(newEmail)}" class="form-group">
          <span class="input-label" [ngClass]="{'disabled' : !isEmailEditable}" for="new-email">New Email Address</span>
          <input type="text" class="form-control" (keydown)=onKeydownEvent($event) [(ngModel)]="newEmail" name="email"
            id="new-email">
          <p aria-live="polite" class="text-indent">
            <span *ngIf="isInvalidEmail" class="icon-error input-error-icon"><span class="input-error">{{ errorMessage }}</span></span>
          </p>
        </div>

        <div *ngIf="changeEmail" class="form-group">
          <p class="curr-email">Enter your password for enhanced security reasons</p>
          <span for="inputCurrentPwd" class="input-label ">Password</span>
          <input type="password" class="form-control" [(ngModel)]="currentPwd" name="currentPwd"
            aria-describedby="currentPwdError" (keydown)="onKeydownEvent($event)" id="inputCurrentPwd"
            autocomplete="off">

          <p class="text-indent">
            <span *ngIf="isErrorCurrentPwd" class="input-error" id="currentPwdError">hmm, that's not the right password,
              Please try again or
              <a *ngIf="isErrorCurrentPwd" class="pointer" (click)="logoutAndredirect()"> <strong>reset your
                  password</strong>
              </a>
            </span>
          </p>
        </div>

        <div *ngIf="!changeEmail" class="form-button">
          <button color="primary" class="themed-button btn btn-primary" id="email_save"
            [ngClass]="{'btnDisabled' : !newEmail}" [disabled]="!newEmail">SAVE</button>
        </div>
        <div *ngIf="changeEmail" class="form-button">
          <button color="primary" class="themed-button btn btn-primary" id="btn_proceed"
            [ngClass]="{'btnDisabled' : !currentPwd}" [disabled]="!currentPwd"
            (click)="verifyPasswordAndUpdateEmail()">Proceed</button>
          <button color="primary" class="themed-button btn btn-primary" id="btn_cancel"
            [ngClass]="{'btnDisabled' : !newEmail}" (click)="cancelEmailUpdate()">Cancel</button>
        </div>

      </form>
      <div *ngIf="user.updateEmail">
        <span class="input-label">New Email Address</span>
        <div class="resend-verification">
          <span><strong>{{newEmail}}</strong></span>
          <a class="link"><strong>Resend Verification</strong></a>
          <a class="link" (click)="unDoEmailUpdate()"><strong>Cancel</strong></a>
        </div>
      </div>
    </div>

  </div>

  <top-notification [message]="updateInfoMessage" [isSuccess]="true" *ngIf="showUdateInfoMessage"></top-notification>

</div>