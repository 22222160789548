<div class="edit-password">
<div class="account-content" role="region" aria-label="Manage Password">
	<div class="account-header-wrapper">
		<div class="account-header">
			<h1>Password</h1>
			<p>Manage your password</p>
		</div>
	</div>
	<div class="account-body-wrapper">
		<form *ngIf="(appState.user$ | async).hasNativeLogin" class="form-bowl" (ngSubmit)="updatePassword()">

			<div [ngClass]="{'has-error': (isErrorCurrentPwd), 'is-empty': !(currentPwd)}"
				class="form-group label-floating">
				<label for="inputCurrentPwd" class="control-label">Current password</label>
				<input type="password" class="form-control" [(ngModel)]="currentPwd" name="currentPwd" aria-describedby="currentPwdError"
					(keydown)="onKeydownEvent($event)" id="inputCurrentPwd" autocomplete="off">
	
				<p class="text-indent">
					<span class="icon-error input-error-icon" *ngIf="isErrorCurrentPwd"> </span>
					<span class="input-error-password" id="currentPwdError" [ngClass]="isErrorCurrentPwd ? 'with-error' : 'no-error'" >{{ errorMessage }}</span>
				</p>
			</div>
			<div [ngClass]="{'has-error': (isErrorNewPwd), 'is-empty': !(newPwd)}"
				class="form-group label-floating new-pwd">
				<label for="inputNewPwd" class="control-label">New password</label>
				<input type="password" class="form-control" [(ngModel)]="newPwd" name="newPwd" aria-describedby="passwordHint"
					(keydown)="onKeydownEvent($event)" id="inputNewPwd" (blur)="validatePassword()"  autocomplete="off">
			
					<p aria-live="polite" class="text-indent" [ngClass]="{'has-hint': (isPwdHintError)}">
					<span class="icon-error input-error-icon" *ngIf="isErrorNewPwd"> </span>
					<span class="input-error-password new-password" id="newPwdError" [ngClass]="isErrorNewPwd ? 'with-error' : 'no-error'"
						>{{ errorMessage }}</span>
					<span class="password-hint hint-error" id="passwordHint"
						*ngIf="isPwdHintError">{{passwordHint}}</span>
				</p>
				<span class="password-hint hint-no-error" *ngIf="!isPwdHintError" id="passwordHint">{{passwordHint}}</span>
			</div>
			<div [ngClass]="{'has-error': (isErrorNewReTypePwd), 'is-empty': !(newReTypePwd)}"
				class="form-group label-floating">
				<label for="inputNewReTypePwd" class="control-label">Re-type new password</label>
				<input type="password" class="form-control" [(ngModel)]="newReTypePwd" name="newReTypePwd" aria-describedby="newReTypePwdError"
					(keydown)="onKeydownEvent($event)" id="inputNewReTypePwd" (blur)="validatePassword()"  autocomplete="off">
					
					<p class="text-indent">
					<span class="icon-error input-error-icon" *ngIf="isErrorNewReTypePwd"> </span>
					<span class="input-error-password" id="newReTypePwdError" [ngClass]="isErrorNewReTypePwd ? 'with-error' : 'no-error'"
						>{{ errorMessage }}</span>
				</p>
			</div>

			<div class="form-button">
				<button color="primary" class="themed-button btn btn-primary" id="change_password_save">SAVE</button>
			</div>
		</form>
		<div *ngIf="isOrcidLogin">
			<p [innerHTML]="orcidPasswordHint"></p>
			<p [innerHTML]="orcidPasswordInfo"></p>
		</div>
		<div *ngIf="isSamlLogin">
			<p [innerHTML]="samlPasswordHint"></p>
			<p [innerHTML]="samlPasswordInfo"></p>
		</div>
	</div>
</div>
<top-notification [message]="updateInfoMessage" [isSuccess]="true" *ngIf="showUdateInfoMessage">

</top-notification>

</div>