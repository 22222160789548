// ANGULAR DEPENDENCIES...
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
//PRIVATE NPM REPO
import { PlatformComponentCommonHeaderComponent } from '@tandfgroup/platform-component-common-header';

import { environment } from '../environments/environment';
import { ThemeService } from './services/theme/theme.service';
import { AppState } from './app.service';
import { UrlConfig } from '../url-config';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  encapsulation: ViewEncapsulation.None,

  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})

export class AppComponent {
  public readonly env = environment.envName;
  @ViewChild(PlatformComponentCommonHeaderComponent) headerComp: PlatformComponentCommonHeaderComponent;
  browserWarning = false;
  themeService: any;
  public brand = 'ubx';
  private baseURL: any = new UrlConfig().getBaseApiUrl();
  private authorizeParams: any;
  skipLinkPath: any;

  constructor(
    public appState: AppState,
    private _themeService: ThemeService,
    private activatedRoute: ActivatedRoute, private _route: Router,
  ) { }

  ngOnInit() {
    // Get brand from query parameters. This is added to relevant HTML element (<main>) in template.
    // That in turns drive various CSS rules for each brand.
    this.InitiateApp();

    this.appState.getInformaEmailDomains().subscribe(
      domains => {
        this.appState.domains$.next(domains);
        this.appState.set('domains', domains);
      },
      (e) => {
        console.log("Error while getting informa domains ", e);
      }
    )

    this.appState.loggedInUser().subscribe(
       user => {
        (async ()=>{
         // Push to user stream
        this.appState.user$.next(user);
        this.appState.set('user', user);
        if (!(this.appState.get('queryParams')['authorize']) && (this.getLocationPath() === '#/' || this.getLocationPath().includes('#/login')) && !user.isValidated) {
          await this._route.navigateByUrl('/verify');
        } else if (!(this.appState.get('queryParams')['authorize']) && (this.getLocationPath() === '#/' || this.getLocationPath().includes('#/login'))) {
          await this.appState.loadUserProfileComponent(true)
          await this._route.navigateByUrl('/accounts');
        } else if (Boolean(this.appState.get('queryParams')['authorize']) && !user.isValidated) {
          await this._route.navigateByUrl('/verify?authorize=true');
        } else if (Boolean(this.appState.get('queryParams')['authorize']) && user.isValidated) {
          const authorizeQueryParams = Object.keys(this.authorizeParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(this.authorizeParams[k])}`).join('&');
          this.setHref(this.baseURL + 'user/auth/authorize?' + authorizeQueryParams);
        } else {
          //Adding User Profile in common Header
          await this.appState.loadUserProfileComponent(true);
        }
        })();
      },
      (e) => {
        console.log("error while getting loggedInUser: ", e);
      }
    );
  }

  ngAfterViewInit() {
    //Setting up common-header viewContainerRef to appState service
    this.appState.headerCompRef = this.headerComp.profileViewRef;
  }

  private getLocationPath() {
    return window.location.hash;
  }

  private setHref(url: string) {
    window.location.href = url;
  }


  setBrand() {
    this.appState.set('brand', this.brand);
  }

  setBrowserMessage(query: Params) {
    this.browserWarning = query['browserWarning'] || this.browserWarning || false;
  }

  InitiateApp() {
    this.themeService = this._themeService.getConfig();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.appState.set('queryParams', params);
      this.setBrand();
      this.setBrowserMessage(params);
      this.setAuthorizeParams(params);
    });

    // No typings for bootstrap-material.
    (<any>jQuery).material.init();
  }

  setAuthorizeParams(query: Params) {
    this.authorizeParams = {
      client_id: query['client_id'] || '',
      response_type: query['response_type'] || '',
      scope: query['scope'] || '',
      redirect_uri: query['redirect_uri'] || '',
      state: query['state'] || ''
    };
    this.appState.set('authorizeParams', this.authorizeParams);
  }
}
