// components/ui/top-notification/top-notification.component.ts

// IMPORTANT NOTE
// If you change this component, and especially the HTML structure of the template,
// you must also make sure the Page Object in `pos/top-notification.po.ts` is uipdated.
// This is used in end-to-end tests.

import { Component, Input } from '@angular/core';

@Component({
  selector: 'top-banner',
  styleUrls: [ './top-banner.component.scss' ],
  templateUrl: './top-banner.component.html'
})
export class TopBannerComponent {

  /*message and isSuccess values are assigned by parent component,
  isSuccess variable is used to display  different icons based on  success or error scenario */
  @Input() message: string;
  @Input() isSuccess: boolean;

  // showNotificationScreen is used to show or close notification
  showNotificationScreen = true;

}
