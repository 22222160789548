<section class="mfa-auth mfa-auth-wrapper">
  <div class="tab-pane fade active in" id="email-code-auth">
    <div class="email-code-auth-wrapper">
      <h1 class="email-code-auth-msg">Authentication</h1>
      <div class="email-code-auth-info">A verification code has been sent to your email address.</div>
      <form class="form-bowl" id="emailCodeAuthForm" autocomplete="off">
        <div [ngClass]="{'has-error': (isError), 'is-empty': !(mfaEmailCode)}" class="form-group label-floating">
          <input type="text" class="form-control" id="inputEmail" [(ngModel)]="mfaEmailCode" name="mfaEmailCode"
            [ngClass]="{'inputError': isError}" [attr.aria-describedby]="isError===true?'codeError':'codeError2'">
          <label for="inputEmail" class="control-label">Verification code</label>
          <span class="icon-error input-error-icon" *ngIf="isError"> </span>
          <span class=" codeError" id="codeError" *ngIf="isError">{{ errorMessage }}</span>
          <p id="codeError2" hidden>{{ errorMessage }}</p>
        </div>



        <div class="form-group terms-check-box">
          <span class="checkbox pull-left no-margin">
            <input type="checkbox" id="deviceCheckBox" (change)="$event.target.checked ? (whiteListDevice = true): (whiteListDevice = false)"
              class="custom-checkbox custom-control-input" />
            <label class="custom-control-label" for="deviceCheckBox">Do not ask again for this device?</label>

            <p id="text-code" hidden>Click to know more about terms and conditions.</p>
          </span>
        </div>


        <div class="form-button-access text-center" id="emailCodeAuthButtonContainer">
          <button (click)="verifyMFACode()" color="primary" id="emailCodeAuthContinueButton"
            class="themed-button btn btn-primary btn-lg">VERIFY</button>
        </div>
        <div class="back-button-wrapper text-center">
          Didn't receive a code through email?
          <a class="links resend-code" href="javascipt:;" (click)="ResendCode()">
            Resend Code
          </a>
        </div>
      </form>
    </div>
  </div>
  <app-loading *ngIf="isLoading"></app-loading>
  <top-notification [message]="sentCodeMessage" [isSuccess]="true" *ngIf="showSentCodeInfoMessage"></top-notification>
</section>