<app-loading *ngIf="loading"></app-loading>
<div class="orcid-email-wrapper">
  <div class="tab-pane fade active in" id="orcidEmail">
    <h1 class="popup-title">Additional information required</h1>
    <form #signUpForm="ngForm" class="form-bowl" autocomplete="off" (ngSubmit)="saveOrcidEmail()" novalidate>
      <div *ngIf='showEmailInput' [ngClass]="{'has-error': isEmailError, 'is-empty': !email}"
        class="form-group label-floating">
        <span for="inputEmail" class="form-label">Email</span>
        <input type="text" class="form-control" id="inputEmail" [(ngModel)]="email" name="email"
          (input)="validateEmailBlur()" (blur)="validateEmailBlur()"
          [attr.aria-describedby]="isEmailError?'mailError':'mailError2'" [ngClass]="{'inputError':isEmailError}">
        <span class="icon-error input-error-icon" *ngIf="isEmailError"> </span>
        <span class="codeError" id="mailError" *ngIf="isEmailError">{{ errorMessage }}</span>
        <span id="mailError2" hidden>{{ errorMessage }}</span>
      </div>
      <div *ngIf="!countryExists" [ngClass]="{'has-error': !country.name && submitted, 'is-empty': !country}"
        class="form-group label-floating">
        <span for="country" class="form-label">Country/Region</span>
        <select class="form-control" (focus)="focusCountry()" (blur)="validateCountryBlur()" id="country"
          [ngClass]="{'inputError':!country.name && submitted}" (ngModelChange)="onCountryChange($event)"
          [(ngModel)]="country" name="country" required>
          <option *ngFor="let country of allCountries" [ngValue]="country">{{ country.name }}</option>
        </select>
        <span class="icon-error input-error-icon" *ngIf="!country.name && submitted"> </span>
        <span class="codeError" *ngIf="!country.name && submitted">{{ errorMsgCountry }}</span>
      </div>
      <div class="form-group optin-text">
        <p id="readPara"> Taylor & Francis Group use the details you share here to create an account for you.<br />

          Taylor & Francis Group would also like to use your email address to send you offers and information about related products and services that we think will be of interest to you.<br />

          You may opt out of receiving these messages at any time by clicking the unsubscribe link found in the footer of our emails.</p> You can find more
        information in
        our
        <a class="underline links" target="_blank" aria-describedby="readPara privacy_policy"
          [href]="privacyPolicyLink">Privacy
          Policy</a>

        <p id="privacy_policy" hidden>Click to find more information about privacy policy.</p>
      </div>

      <div
        class="form-group terms-check-box">
        <span class="checkbox pull-left no-margin">

          <input type="checkbox" [checked]="optout" id="signup-chk1" class="custom-checkbox  custom-control-input"
            [(ngModel)]="optout" name="optout" />
          <label class="custom-control-label" for="signup-chk1">If you do not want to receive marketing communication
            from us, please tick the box</label>

        </span>
      </div>

      <div class="form-group terms-check-box">
        <span class="checkbox pull-left no-margin">

          <input type="checkbox" id="termsCheckBox" class="custom-checkbox  custom-control-input"
            [(ngModel)]="isTermsChecked" aria-describedby="t&cError" name="isChecked" />
          <label class="custom-control-label" id="termsAndCondition" for="termsCheckBox"> I agree to the <a
              class="underline links" target="_blank" [href]="termsOfUseLink" aria-describedby="terms_conditions">Terms
              & Conditions</a></label>

          <p id="terms_conditions" hidden>Click to know more about terms and conditions.</p>
        </span>
      </div>
      <p id="t&cError" hidden>* Agreement to Terms & Conditions is
        required</p>
      <div *ngIf="!isTermsChecked && submitted" class="error-msg">* Agreement to Terms & Conditions is
        required</div>
      <div class="form-button-access text-center">
        <button color="primary" class="custom-button themed-button btn btn-primary btn-lg">Continue</button>
      </div>
    </form>
  </div>
</div>