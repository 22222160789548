import { Component } from '@angular/core';

@Component({
  selector: 'access-deny',
  template: `
    <div style="background:white;padding: 40px 20px 50px 20px;
    margin: 20px;">
        <h2 class="text-center">Unauthorised Access.</h2>
        <h5 style="text-align: center;line-height: normal;">You don't have permission to view the content.</h5>
    </div>
  `
})

export class AccessDeniedComponent {}
