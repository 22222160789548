import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformCommonServiceCommonDialogModalService } from '@tandfgroup/platform-component-common-dialog-modal';

import { AccountsService } from '../../../services/accounts-api/accounts-api.service';
import { AppState } from '../../../app.service';
import { isValidEmail } from '@tandfgroup/form-field-validator'


@Component({
  selector: 'update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateEmailComponent implements OnInit {

  isInvalidEmail: boolean;
  showUdateInfoMessage: boolean;
  email: string;
  username: string;
  isEmailEditable: boolean;
  user: any;
  subscription: any;
  newEmail: string;
  currentPwd: string;
  changeEmail: boolean;
  isErrorCurrentPwd: boolean;
  errorMessage: string;
  loading: boolean;
  constructor(
    private appState: AppState,
    private router: Router,
    private acService: AccountsService,
    private pfcommonDialogModalService: PlatformCommonServiceCommonDialogModalService
  ) { }

  ngOnInit() {
    this.subscription = this.appState.user$.subscribe((user) => {
      this.user = user;
      this.email = this.user.email ? this.user.email : '';
      this.username = this.user.username;
    });
  }

  onKeydownEvent(event: any) {
    if (event.srcElement.id === 'new-email') {
      this.isInvalidEmail = false;
    }
    if (event.srcElement.id === 'inputCurrentPwd') {
      this.isErrorCurrentPwd = false;
    }
  }

  updateEmail() {
    if (!isValidEmail(this.newEmail)) {
      this.isInvalidEmail = true;
      this.errorMessage = "Must be an email address.";
      return;
    }
    this.changeEmail = true;
  }

  verifyPasswordAndUpdateEmail() {
    this.loading = true;
    this.acService.verifyPassword(this.username, this.currentPwd)
      .subscribe((res) => {
        this.acService.updateEmail(this.username, { updateEmail: this.newEmail })
          .subscribe((body) => {
            this.loading = false;
            this.user.updateEmail = this.newEmail;
            this.pfcommonDialogModalService.openInfoModal("We have sent you a verification message to your registered email address " + this.newEmail + ". Please check your mail.", {});
          }, (err) => {
            this.loading = false;
            this.isInvalidEmail = true;
            this.errorMessage = "An account already exists with this email " + this.newEmail + ". Try entering a different email address."
            this.cancelEmailUpdate();
          })
      }, (err) => {
        this.loading = false;
        this.isErrorCurrentPwd = true;
      })
  }

  logoutAndredirect() {
    this.loading = true;
    this.appState.getTokenAndlogoutUser().finally(() => {
      (async ()=>{
        this.loading = false
        this.appState.headerCompRef?.clear();
        await this.router.navigate(["/reset-password"], { queryParams: { reset: true }, replaceUrl: true });
      })();
    })
  }

  cancelEmailUpdate() {
    this.changeEmail = false;
    this.isErrorCurrentPwd = false;
    this.currentPwd = '';
  }

  unDoEmailUpdate() {
    this.pfcommonDialogModalService.openConfirmModal('Do you really want to Cancel ?',
      (isConfirmed: boolean) => {
        if (isConfirmed) {
          this.acService.unDoEmailUpdate(this.username, { updateEmail: this.newEmail })
            .subscribe((res) => {
              this.user.updateEmail = '';
            }, (err) => {
              console.log("error :{}", err);
            })
        }
      },      
      { data: { confirmButtonLabel: 'Yes', title: 'Cancel Email Update' } }
    );
  }

}
