import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UrlConfig } from '../../../url-config';

@Injectable({ providedIn: 'root' })

export class SignUpService {

    private baseURL: any = new UrlConfig().getBaseApiUrl();
    private countriesUrl: any = new UrlConfig().getCountriesUrl();

    private signUpURL = this.baseURL + "user/auth/signUp";


    constructor(private http: HttpClient) { }

    register(reqPayload: any): Observable<any> {
        return this.http.post(this.signUpURL, reqPayload);
    }

    fetchCountries(): Promise<any> {
        const url = this.countriesUrl;
        return this.http.get(url)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch(err => {
                return this.handleError(err);
            });
    }




    private handleError(error: HttpErrorResponse) {
        // Return an observable with a user-facing error message.
        let errMsg = {
            status: error.status,
            metadata: error.error?.metadata
        };
        return throwError(errMsg);
    }
}
