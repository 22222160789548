import { Component } from '@angular/core';

import { ConsentService } from './consent.service';
import { AppState } from '../../app.service';
import { UrlConfig } from '../../../url-config';
@Component({
  selector: 'consent',
  templateUrl: 'consent.component.html',
  styleUrls: ['consent.component.scss'],
  providers: [ConsentService],
})
export class ConsentComponent {

  public baseURL: any = new UrlConfig().getBaseApiUrl();

  localState: any = {
    clientName: '',
    clientId: '',
    scope: {},
    redirectUrl: '',
    addClass: jQuery('main').addClass('main-content'),
    removeClass: jQuery('main').removeClass('main-image'),
    consentAPI: this.baseURL + 'user/auth/authorize'
  };

  constructor(
    private consent: ConsentService,
    public appState: AppState,
  ) { }

  ngOnInit() {
    document.getElementById('allowButton').focus();
    this.localState.redirectUrl = this.baseURL + "user/auth/consent";
    if (Object.keys(this.appState.get('queryParams')).length > 0) {
      this.localState.clientId = this.appState.get('queryParams')['client_id'];
      this.localState.redirectUrl = encodeURI(this.localState.redirectUrl + '?client_id=' + this.localState.clientId + '&return_url=' + this.appState.get('queryParams')['return_url'] + '&state=' + this.appState.get('queryParams')['state']);
      this.localState.clientName = this.appState.get('queryParams')['client_name'];
      this.localState.scope = JSON.parse(this.appState.get('queryParams')['scope']);
    }
  }

  onClick(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    jQuery('.label-information').addClass("notVisible");
    jQuery('.bar').removeClass("displayedInfo");
    jQuery('.emailAddress').removeClass('displayedBlock');
    jQuery(target).next().removeClass("notVisible");
    jQuery(target).next().next().addClass("displayedInfo");
    jQuery(target).parents('.emailAddress').addClass('displayedBlock');
  }
}
