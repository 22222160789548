import { Injectable } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
const countryEndPoint = "geolocation/countries/all";

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        /** Pass untouched request through to the next request handler in case of country req. */
        if (req.url.includes(countryEndPoint)) {
            return next.handle(req);
        } else {
            const headers = req.headers;
            headers.set('Content-Type', 'application/json');
            const headerReq = req.clone({ headers: headers, withCredentials: true });
            return next.handle(headerReq).pipe(
                map(ev => {
                    if (ev instanceof HttpResponse) {
                        return this.extractBody(ev);
                    }
                }), catchError(this.handleError)
            );
        }

    }

    private extractBody(ev) {
        return ev.clone({ body: ev.body?.data || {} });
    }

    private handleError(error: HttpErrorResponse) {
        console.log("HttpErrorResponse error:: ", error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        // Return an observable with a user-facing error message.
        let errMsg = {
            status: error.status,
            metadata: error.error.metadata
        };
        return throwError(errMsg);
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: IdentityInterceptor, multi: true },
];