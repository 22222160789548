<tandfgroup-platform-component-common-header
   [class]="brand"
   [themeService]="themeService">
</tandfgroup-platform-component-common-header>

<main [class]="brand">
  <router-outlet></router-outlet>
</main>

<tandfgroup-platform-component-common-footer
   [class]="brand"
   [themeService]="themeService">
</tandfgroup-platform-component-common-footer>
