<div mat-dialog-content class="dialog-content">
    <div class="dialog-title">
        <p class="cstm-dialog-title"><strong>Verification pending</strong></p>
        <button class="cstm-icon-btn" [mat-dialog-close]="false"><em class="icon-cancel"></em></button>
    </div>
    <p class="cstm-dialog-description"> Your email address has not been verified yet.
        Please check your inbox for the verification link. If you have not received it, click on resend email</p>
</div>
<mat-dialog-actions align="left" class="dialog-action">
    <button mat-raised-button cdkFocusInitial [mat-dialog-close]="true" color="primary">RESEND EMAIL</button>
    <button mat-stroked-button [mat-dialog-close]="false">CANCEL</button>
</mat-dialog-actions>