<section class="account-section container">
  <input id="toggle" type="checkbox" [(ngModel)]="showAccountsSidePanel">
  <label class="toggle-label mobile-toggle" for="toggle"><span class="hidden">Toggle</span></label>
  <div id="expand" class="account-sidebar">
    <ul role="tablist">
      <li role="tab" id="tab1" [attr.aria-selected]="(currentRoute === 'your-account')" (keydown)="tabKey($event)"
        [attr.tabindex]=" 0 " (click)="setActiveTab('your-account')"
        [ngClass]="{'active': (currentRoute === 'your-account')}" [routerLink]="['your-account']">
        <a><em class="icon-user"></em>Your Account</a>
      </li>
      <li role="tab" id="tab2" [attr.aria-selected]="(currentRoute === 'edit-password')"
        [routerLink]="['edit-password']" (keydown)="tabKey($event)"
        [attr.tabindex]=" 0 "
        [ngClass]="{'active': (currentRoute === 'edit-password')}" (click)="setActiveTab('edit-password')">
        <a><em class="icon-password"></em>Change Password</a>
      </li>
     <!-- Temporary using markedForDeletion property to disable remove account tab -->
      <li *ngIf="(appState.user$ | async).markedForDeletion" role="tab" id="tab3"
        [attr.aria-selected]="(currentRoute === 'remove-account')" [routerLink]="['remove-account']"
        (keydown)="tabKey($event)" [attr.tabindex]=" 0 "
        [ngClass]="{'active': (currentRoute === 'remove-account')}" (click)="setActiveTab('remove-account')">
        <a><em class="icon-delete"></em>Remove Account</a>
      </li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</section>
