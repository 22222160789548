/*
 * Orcid component
 * When user is logged in through orcid,
 * If user response from orcid comes without email, then user will be navigated to this component to provide their email.
 */
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { isValidEmail } from '@tandfgroup/form-field-validator'
import { OrcidShibbolethService } from './orcid-shibboleth.service';
import { AppState } from '../../app.service';
import { UrlConfig } from '../../../url-config';
import { sortBy } from 'lodash-es';
import { OrcidDialogBoxComponent } from '../../components/ui/orcid-dialog-box/orcid-dialog-box.component';
import { Subscription } from 'rxjs';
import { AuthapiService } from '../../services/authapi/authapi.service';
import { DOCUMENT } from '@angular/common';

const baseURL: string = new UrlConfig().getBaseApiUrl();
const TIMEOUT_INTERVAL = 2000;

@Component({
  selector: 'orcid-shibboleth',
  styleUrls: ['./orcid-shibboleth.component.scss'],
  templateUrl: './orcid-shibboleth.component.html',
  providers: [OrcidShibbolethService],
  encapsulation: ViewEncapsulation.None
})
export class OrcidShibbolethComponent {
  public sPClientId: string = new UrlConfig().getSPClientId();

  config = new UrlConfig();
  email: string = '';
  country: any = {};
  optout: boolean = false;
  isTermsChecked: boolean = false;
  isEmailError: boolean = false;
  submitted: boolean = false;
  emailCannotBeEmptyMessage: string = 'Email address cannot be empty.';
  errorMsgCountry: string = 'Country/Region cannot be empty';
  invalidEmailFormatMessage: string = 'Must be an email address.';
  errorMessage: string = '';
  clientId: string = '';
  authorize: boolean = false;
  authorizeAction: string = baseURL + 'user/auth/authorize';
  isIncorrectCountry: boolean = false;
  allCountries: any[] = [];
  isShibboleth: boolean = false;
  isTnfLogin: boolean = false;
  userId: string = '';
  private timer;
  clientConfig: any = {};
  termsOfUseLink: string = '';
  privacyPolicyLink: string = '';
  skipWechatLogin: boolean = false;
  countryExists: boolean = false;
  isOrcidLogin: boolean = false;
  state: string = '';
  hideOptOut = false;
  usersDataSubscription?: Subscription;
  loading: boolean = false;
  showEmailInput = false

  constructor(
    public orcidShibbolethService: OrcidShibbolethService,
    public appState: AppState,
    public _route: Router,
    public dialog: MatDialog,
    private authServices: AuthapiService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.clientConfig = this.config.getClientConfig(this.appState.get('brand'));
    this.termsOfUseLink = this.clientConfig.termsOfUseLink;
    this.privacyPolicyLink = this.clientConfig.privacyPolicy;
    if (Object.keys(this.appState.get('queryParams')).length > 0) {
      this.clientId = this.appState.get('queryParams')['client_id'];
      this.authorize = Boolean(this.appState.get('queryParams')['authorize']);
      this.isShibboleth = Boolean(this.appState.get('queryParams')['isShibboleth']);
      this.isOrcidLogin = Boolean(this.appState.get('queryParams')['isOrcid']);
      this.country.name = this.countryExists = this.appState.get('queryParams')['countryExists'];
      this.isTnfLogin = Boolean(this.appState.get('queryParams')['isTnfLogin']);
      this.userId = this.appState.get('queryParams')['userId'];
      this.state = this.appState.get('queryParams')['state'];
      this.optout = this.appState.get('queryParams')['optOut'] == 'true';
      this.showEmailInput = this.appState.get('queryParams')['getEmailId'];
    }
    if (!this.countryExists) {
      this.fetchCountries();
    }
    this.usersDataSubscription = this.appState.user$.subscribe(userData => {
      this.hideOptOut = [true, false].includes(userData.optOut);
      this.optout = userData.optOut || false;
    })
  }

  saveOrcidEmail() {
    this.submitted = true;
    this.isEmailError = false;
    this.isIncorrectCountry = false;
    this.email = this.email.trim();

    this.emailErrorOnSaveORCIDEmail();

    if (this.country.name && this.country.name === 'China' && this.clientId && this.clientId === this.sPClientId && !this.skipWechatLogin && this.isOrcidLogin) {
      this.openDialog();
    } else if (!this.isEmailError && this.country.name && this.isTermsChecked && this.isOrcidLogin) {
      this.updateORCIDUserOnSaveORCIDEmail();
    } else if (!this.countryExists && !this.country.name) {
      document.getElementById("country").focus();
    } else if (!this.isTermsChecked) {
      document.getElementById("termsCheckBox").focus();
    } else if (this.country.name && this.isTermsChecked && this.isShibboleth) {
      this.updateSibUserOnSaveORCIDEmail();
    } else if (this.country.name && this.isTermsChecked && this.isTnfLogin) {
      this.updateTnFUserOnSaveORCIDEmail();
    }
  }

  emailErrorOnSaveORCIDEmail() {
    if (this.showEmailInput && (!this.email.trim() || !isValidEmail(this.email.trim()))) {
      this.isEmailError = true;
      this.errorMessage = !this.email.trim() ? this.emailCannotBeEmptyMessage : this.invalidEmailFormatMessage;
      document.getElementById("inputEmail").focus();
    }
  }

  updateSibUserOnSaveORCIDEmail(){
    let body = {
      userId: this.userId,
      optOut: this.optout,
      address: undefined,
      hasAcceptedTerms: this.isTermsChecked
    };

    if (!this.countryExists) { body.address = { country: this.country.name } }

    if (this.clientId) {
      body['clientId'] = this.clientId;
    }
    this.orcidShibbolethService.updateShibbolethUser(body)
      .subscribe(
        (response) => {
          (async () => {
            await this.setResponse(response);
          })();
        },
        (err: any) => {
          this.setErrorResponse(err);
        }
      );
  }

  updateTnFUserOnSaveORCIDEmail(){
    let body = {
      userId: this.userId,
      optOut: this.optout,
      address: undefined,
      hasAcceptedTerms: this.isTermsChecked,
      consentGiven: true
    };

    if (!this.countryExists) { body.address = { country: this.country.name } }

    if (this.clientId) {
      body['clientId'] = this.clientId;
    }

    this.orcidShibbolethService.updateTnfUser(body)
      .subscribe(
        (response) => {
          (async () => {
            await this.setResponse(response);
          })();
        },
        (err: any) => {
          this.setErrorResponse(err);
        }
      );
  }

  updateORCIDUserOnSaveORCIDEmail(){
    let body = {
      email: this.email,
      optOut: this.optout,
      hasAcceptedTerms: this.isTermsChecked,
      address: undefined
    };
    this.loading = true;
    if (!this.countryExists) { body.address = { country: this.country.name } }

    if (this.clientId) {
      body['clientId'] = this.clientId;
    }

    this.orcidShibbolethService.updateOrcidUser(body)
      .subscribe(
        (response) => {
          (async () => {
            await this.handleOrcidResponse(response)
          })();
        },
        (err: any) => {
          this.loading = false;
          this.setErrorResponse(err);
        }
      );
  }

  /**
   * Sets The Error Variables Based On the Error Response.
   * Checks whether password was incorrect or Email
   * @param {any} errorMetadata Error Response Sent Back By The API
   */
  private setErrorStateVariables(errorMetadata: any) {
    console.log("inside setErrorStateVariables orcid", errorMetadata);
    this.isEmailError = true;
    this.errorMessage = errorMetadata.message;
    document.getElementById("inputEmail").focus();
  }

  private async redirectToNextPage() {
     await this.appState.loadUserProfileComponent(true);
     await this._route.navigate(['/accounts']);
  }

  openDialog() {
    const dialogRef = this.dialog.open(OrcidDialogBoxComponent, { width: "525px", disableClose: true, panelClass: 'orcid-dialog-section' });

    dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
      (async ()=>{
        if (isConfirmed) {
          await this._route.navigate(['/sign-up'], { queryParams: { authorize: this.authorize, client_id: this.clientId, state: this.state } });
        } else {
          this.skipWechatLogin = true;
          this.saveOrcidEmail();
        }
      })();
    })
  }

  validateEmailBlur() {
    if (this.email === '') {
      this.isEmailError = true;
      this.errorMessage = this.emailCannotBeEmptyMessage;
    }
    else if ((!isValidEmail(this.email))) {
      this.isEmailError = true;
      this.errorMessage = this.invalidEmailFormatMessage;
    }
    else {
      this.isEmailError = false;
    }
  }

  validateCountry() {
    this.isIncorrectCountry = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.validateCountryBlur();

    }, TIMEOUT_INTERVAL);
  }

  focusCountry() {
    this.isIncorrectCountry = false;
  }

  validateCountryBlur() {
    if (this.country.name.length < 2) {
      this.errorMsgCountry = "Country/Region required";
      this.isIncorrectCountry = true;
      document.getElementById("country").focus();
    }
  }

  fetchCountries() {
    this.orcidShibbolethService.fetchCountries().then(response => {
      this.allCountries = sortBy(response.countries, 'name');
    })
    .catch((err)=>{console.log(err)})
  }

  onCountryChange(country) {
    this.country.name = country.name;
  }

  async setResponse(response) {
    let user = response;
    //console.log(`Is auth flow --  ${this.authorize} :: has user accepted terms --${user.hasAcceptedTerms} :: user id --${user._id}`);
    // User already accepted the terms then log user in, also it shouldnt be a tnflogin since Mfa is already done
    if (user.mFAEnabled && !user.mFAAuthenticated && !this.isTnfLogin) {
      await this.routeMultiFactorAuth(user);
    } else if (user.hasAcceptedTerms) {
      if (this.authorize || this.clientId) {
        const authorizeParams = this.appState.get('authorizeParams');
        const authorizeQueryParams = Object.keys(authorizeParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(authorizeParams[k])}`).join('&');
        this.setHref(this.authorizeAction + "?" + authorizeQueryParams);
      } else {
        this.appState.user$.next(user);
        this.appState.set('user', user);
        await this.redirectToNextPage();
      }
      // If not redirect to accept terms page
    } else {
      await this.routeAcceptTerms(user);
    }
  }

  async routeMultiFactorAuth(user) {
    if (this.authorize) {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: user._id, state: this.state } });
    } else if (this.clientId) {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { userId: user._id, client_id: this.clientId, state: this.state } });
    } else {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { userId: user._id } });
    }
  }

  async routeAcceptTerms(user){
    if (this.authorize) {
      await this._route.navigate(['/accept-terms'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: user._id, state: this.state } });
    } else if (this.clientId) {
      await this._route.navigate(['/accept-terms'], { queryParams: { userId: user._id, client_id: this.clientId, state: this.state } });
    } else {
      await this._route.navigate(['/accept-terms'], { queryParams: { userId: user._id } });
    }
  }

  setErrorResponse(err) {
    this.setErrorStateVariables(err.metadata);
  }

  ngOnDestroy(){
    this.usersDataSubscription?.unsubscribe();
  }

  setHref(url: string) {
    this.document.location.href = url;
  }

  async handleOrcidResponse(userData: any) {
    if (userData.needMfaValidation || !userData.isValidated) {
      this.authServices.sendMFACode(userData.id || userData._id).subscribe(
        () => {
          this.loading = false;
          if (this.authorize) {
            this._route.navigate(['/multi-factor-auth'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: userData._id } });
          } else {
            this._route.navigate(['/multi-factor-auth'], { queryParams: { client_id: this.clientId, userId: userData._id } });
          }
        },
        err => {
          this.loading = false;
          this.setErrorResponse(err);
        }
      )
    } else {
      this.loading = false;
      return this.setResponse(userData);
    }
  }
}
