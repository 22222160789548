<div class="remove-account">
  <div class="account-content" role="region" aria-label="Remove Account">
    <div class="account-header-wrapper">
      <div class="account-header">
        <h1>Remove Account</h1>
        <p>Delete your account</p>
      </div>
    </div>
    <div *ngIf="!isMarkAsDeleted" class="account-body-wrapper">
      <form class="form-bowl" (ngSubmit)="markForDeletion()">
        <div [ngClass]="{'has-error': (isErrorCurrentPwd), 'is-empty': !(password)}" class="form-group label-floating">
          <label for="inputCurrentPwd" class="control-label">Enter password</label>
          <input type="password" class="form-control" [(ngModel)]="password" name="password"
            aria-describedby="currentPwdError" (ngModelChange)="onInputChange()" id="inputCurrentPwd"
            autocomplete="off">
          <p class="text-indent">
            <span class="icon-error input-error-icon" *ngIf="isErrorCurrentPwd"> </span>
            <span class="input-error-password" id="currentPwdError"
              [ngClass]="isErrorCurrentPwd ? 'with-error' : 'no-error'">{{ errorMessage }}</span>
          </p>
        </div>
        <div class="form-button">
          <button color="primary" class="themed-button btn btn-primary" id="confirm_button"
            type="submit">CONFIRM</button>
          <button color="primary" class="themed-button btn btn-primary" id="clear_button" type="reset">CLEAR</button>
        </div>
      </form>
    </div>
    <div *ngIf="isMarkAsDeleted" class="account-body-wrapper">
      <span class="ac-deletion-inprogress">Your Account deletion is in progress ...</span>
    </div>
  </div>
  <top-notification [message]="updateInfoMessage" [isSuccess]="true" *ngIf="showUdateInfoMessage"></top-notification>
</div>
