<top-notification [message]="notificationMsg" [isSuccess]="true" *ngIf="resentEmail"></top-notification>

<div *ngIf="isVerificationCodeExpired" class="verifyRegistrationContainer">
    <div>
        <em class="icon-error"></em>
    </div>
    <p class="message"> Verification failed</p>
    <div> Your verification link has expired. </div>
    <a class="resendLink" (click)="resendEmail()">Resend the email</a>
</div>

<div *ngIf="isInvalidVerificationToken" class="verifyRegistrationContainer">
    <div>
        <em class="icon-error"></em>
    </div>
    <p class="message"> Verification failed</p>
    <div> Please click on the latest verification link sent to your mail.</div>
</div>
