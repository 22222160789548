import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UrlConfig } from './url-config';


import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

window.addEventListener('hashchange', () => {
  location?.hash.indexOf('client_id') >= 0 && window.location.reload();
}, false);

getClientConfig().then(_ => {
  platformBrowserDynamic().bootstrapModule(AppModule);
})

function getClientConfig() {
  let defaultClientConfig: any = new UrlConfig().getdefaultClientConfig();

  return new Promise((resolve, reject) => {
    const queryParams = getParamsAfterHash(window.location.href);
    const client_id = queryParams["client_id"];

    if (client_id) {
      sessionStorage.setItem("clientId",client_id);
      fetch("settings/" + client_id + ".json")
        .then((response) => {
          if (response.status == 200) { return response.text() } else {
            throw new Error("not valid config")
          }
        })
        .then((data) => {
          try {
            localStorage.setItem("clientConfig", data); resolve(data);
          } catch (error) {
            localStorage.setItem("clientConfig", defaultClientConfig);
            resolve(defaultClientConfig);
          }
        })
        .catch((e) => {
          localStorage.setItem("clientConfig", defaultClientConfig);
          resolve(defaultClientConfig);
        }
        );
    } else {
      localStorage.setItem("clientConfig", defaultClientConfig);
      resolve(defaultClientConfig);
    }
  });
}

function getParamsAfterHash(url) {
  if (typeof url !== "string" || !url) url = location.href;
  url = url.split("#")[1];
  if (!url) return {};
  url = url.split("?")[1];
  if (!url) return {};
  return url.split("&").reduce(function (result, param) {
    var [key, value] = param.split("=");
    result[key] = value;
    return result;
  }, {});
}