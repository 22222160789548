import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../../app.service';

@Component({
  selector: 'app-login-description',
  templateUrl: './login-description.component.html',
  styleUrls: ['./login-description.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginDescriptionComponent implements OnInit {

  loginDescription = '';

  constructor(private appService: AppState) { }

  ngOnInit(): void {
    this.loginDescription = this.appService.getClientConfig().description;
  }

}
