/*
 * Accept terms and condition component.
 * When user logs in, They have to accept terms and condition in order to succefully log in.
 *
 */
import { Component, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { AccountsService } from "../../../services/accounts-api/accounts-api.service";
import { UrlConfig } from '../../../../url-config';
import { AppState } from '../../../app.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'accept-terms',
  styleUrls: [ './accept-terms.component.scss' ],
  templateUrl: './accept-terms.component.html'
})
export class AcceptTermsComponent {
  config = new UrlConfig();
  baseURL: string = this.config.getBaseApiUrl();
  clientConfig: any = {};
  termsOfUseLink: string = '';
  authorizeAction: string = this.baseURL + 'user/auth/authorize';
  clientId: string = '';
  authorize: boolean = false;
  userId: string = '';
  state: string = '';

  constructor( private _route: Router, private appState: AppState, private accountsService: AccountsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    // Get client config
    this.clientConfig = this.config.getClientConfig(this.appState.get('brand'));
    this.termsOfUseLink = this.clientConfig.termsOfUseLink;
    if (Object.keys(this.appState.get('queryParams')).length > 0) {
      this.clientId = this.appState.get('queryParams')['client_id'];
      this.authorize = Boolean(this.appState.get('queryParams')['authorize']);
      this.userId = this.appState.get('queryParams')['userId'];
      this.state = this.appState.get('queryParams')['state'];
    }
  }

  // If user decline's then navigate to login page.
  async declineTerms() {
    if (this.authorize) {
      await this._route.navigate(['/login'], { queryParams: { authorize: this.authorize, clientId: this.clientId, state: this.state }});
    } else {
      await this._route.navigate(['/login']);
    }
  }

  // If user accept the terms and condition,
  // Then update the user and navigate to next page.
  acceptTerms() {
    this.accountsService.updateAccount(this.userId, { hasAcceptedTerms: true })
    .subscribe(
      (response) => {
        (async ()=>{
          if (this.authorize) {
            let authorizeParams = this.appState.get('authorizeParams');
            let authorizeQueryParams = Object.keys(authorizeParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(authorizeParams[k])}`).join('&');
            this.setHref(this.authorizeAction + "?" + authorizeQueryParams)
          } else {
            this.appState.user$.next(response);
            this.appState.set('user', response);
            await this._route.navigate(['/accounts']);
          }
        })();
      },
      (err) => {
        console.log('Unable to update user', err)
      }
    )
  }

  setHref(url: string){
    this.document.location.href = url;
  }
}
