import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UrlConfig } from '../../../url-config';
@Injectable()
export class OrcidShibbolethService {
  private baseURL: string = new UrlConfig().getBaseApiUrl();
  private updateOrcidUserURL = `${this.baseURL}user/orcid/`;
  private updateShibbolethUserURL = `${this.baseURL}user/shibboleth/`;
  private updatetnfUserURL = `${this.baseURL}user/tnf-consent/`;
  private countriesUrl: any = new UrlConfig().getCountriesUrl();

  constructor(private http: HttpClient) { }

  updateOrcidUser(updatedUserData): Observable<any> {
    return this.http.put(this.updateOrcidUserURL, updatedUserData);
  }

  private handleError(error: HttpErrorResponse) {
    // Return an observable with a user-facing error message.
    let errMsg = {
      status: error.status,
      metadata: error.error.metadata
    };
    return throwError(errMsg);
  }

  fetchCountries(): Promise<any> {
    const url = this.countriesUrl;
    return this.http.get(url)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch(err => {
        return this.handleError(err);
      });
  }

  updateShibbolethUser(updatedUserData): Observable<any> {
    return this.http.put(this.updateShibbolethUserURL, updatedUserData);
  }

  updateTnfUser(updatedUserData): Observable<any> {
    return this.http.put(this.updatetnfUserURL, updatedUserData);
  }
}
