<section class="consent-page">
  <div class="consent-page-wrapper">
    <div id="consent-pageContent" class="tab-content">
      <div class="tab-pane text-center fade active in" id="consent-page-container">
        <div class="headerconatiner">
          <h1 class="consentPageMgs">Request for permission</h1>
          <div class="consentPageMgs-info">The application is requesting access for the following:</div>
        </div>
        <div class="form-container">
          <ul>
            <mat-list>
              <mat-list-item *ngFor="let scope of this.localState.scope">
                <div class="emailAddress form-group is-empty">
                  <p *ngIf="scope.name!='eReader'">{{scope.name}}
                    <span (click)=" onClick($event)" class="infoIcon"> </span>
                    <label class="label-information notVisible">{{ scope.description }}</label>

                  </p>
                  <p *ngIf="scope.name=='eReader'" class="eReaderText">
                    To transfer you to our eReader and personalised functionality, we require consent to share your
                    username with this application.<br>
                    <br> Please select allow to continue
                  </p>


                </div>
              </mat-list-item>
              <em class="bar"></em>
            </mat-list>
          </ul>
          <form class="buttonConatiner" ngNoForm method="POST" action="{{this.localState.redirectUrl}}">
            <button name="cancel" value="cancel" type="submit" color="primary" id="donotallowButton"
              class="btn btn-outline btn-lg">DON'T ALLOW</button>
            <button name="accept" value="accept" type="submit" color="primary" id="allowButton"
              class="btn btn-outline btn-lg">ALLOW</button>
          </form>
        </div>
        <div class="info-container">
          <p>By clicking Allow, you allow this application and T&F to use your information in accordance with
            their respective terms of service and privacy policies.<br> You can change this and other
            account permissions at any time.</p>
        </div>
      </div>
    </div>
  </div>
</section>
