// app.module.ts
//
// Application module definition.

// ANGULAR
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// MATERIAL
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';


// THIRD PARTIES
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { AppState } from './app.service';
import { AppComponent } from './app.component';

// SERVICES
import { httpInterceptorProviders } from './services/http-interceptor';

// components/pages
import { NoContentComponent } from './components/pages/no-content/no-content.component';
import { AccessDeniedComponent } from './components/pages/access-denied/access-denied.component';

// components/ui
import { AcceptTermsComponent } from './components/ui/accept-terms/accept-terms.component';
import { MFAComponent } from './components/ui/multi-factor-auth/multi-factor-auth.component';
import { TopNotificationComponent } from './components/ui/top-notification/top-notification.component';
import { TopBannerComponent } from './components/ui/top-banner/top-banner.component';
import { TitleComponent } from './components/ui/title/title.component';
import { NoImageComponent } from './components/ui/no-image/no-image.component';

// modules
import { LoginRegisterComponent } from './modules/login-register/login-register.component';
import { AccountComponent } from './modules/account/account.component';
import { MyAccountComponent } from './modules/account/my-account/my-account.component';
import { EditPasswordComponent } from './modules/account/edit-password/edit-password.component';
import { LinkAccountComponent } from './modules/account/link-account/link-account.component';

import { ConsentComponent } from './modules/consent/consent.component';
import { OrcidShibbolethComponent } from './modules/orcid-shibboleth/orcid-shibboleth.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { VerifyRegistrationComponent } from './modules/verify-registration/verify-registration.component';
import { PlatformComponentCommonPrivilegesServiceModule } from '@tandfgroup/platform-component-common-privileges-service';
import { PlatformComponentCommonHeaderModule } from '@tandfgroup/platform-component-common-header';
import { PlatformComponentCommonFooterModule } from '@tandfgroup/platform-component-common-footer';
import { PlatformComponentCommonDialogModalModule } from '@tandfgroup/platform-component-common-dialog-modal';

// ROUTES
import { ROUTES } from './app.routes';
import { LoadingComponent } from './components/ui/loading/loading.component';
import { RemoveAccountComponent } from './modules/account/remove-account/remove-account.component';

import { environment } from '../../src/environments/environment';
import { LoginDescriptionComponent } from './modules/login-register/login-description/login-description.component';
import { UpdateEmailComponent } from './modules/account/update-email/update-email.component';
import { OrcidDialogBoxComponent } from './components/ui/orcid-dialog-box/orcid-dialog-box.component';
import { VerificationPendingDialogComponent } from './components/ui/verification-pending-dialog/verification-pending-dialog.component';
import { WayflessOrgComponent } from './components/pages/wayfless-org/wayfless-org.component';
import { PasswordExpiredDialogComponent } from './components/ui/password-expired-dialog/password-expired-dialog.component';

// Turn this on to enable Angular route tracing in console.
const ENABLE_ROUTE_TRACING = false;

@NgModule({
  imports: [
    // ANGULAR
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES, { useHash: true, enableTracing: ENABLE_ROUTE_TRACING, scrollPositionRestoration: 'enabled' }),
    HttpClientModule,
    NoopAnimationsModule,
    // MATERIAL
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,

    // THIRD PARTIES
    AutocompleteLibModule,

    // COMMON
    // CommonComponentsModule,
    // RavenModule,
    PlatformComponentCommonHeaderModule,
    PlatformComponentCommonFooterModule,
    PlatformComponentCommonDialogModalModule,
    PlatformComponentCommonPrivilegesServiceModule.forRoot(environment.envName)
  ],
  providers: [
    AppState,
    Title,
    httpInterceptorProviders
  ],
  declarations: [
    AppComponent,

    // components from /components/pages
    NoContentComponent,
    AccessDeniedComponent,

    // components from /components/ui
    AcceptTermsComponent,
    MFAComponent,
    TopNotificationComponent,
    TopBannerComponent,
    TitleComponent,
    NoImageComponent,

    // components from the misnamed `modules` directory
    AccountComponent,
    ConsentComponent,
    LoginRegisterComponent,
    OrcidShibbolethComponent,
    ResetPasswordComponent,
    SignUpComponent,
    VerifyRegistrationComponent,
    MyAccountComponent,
    EditPasswordComponent,
    LinkAccountComponent,
    LoadingComponent,
    RemoveAccountComponent,
    LoginDescriptionComponent,
    UpdateEmailComponent,
    OrcidDialogBoxComponent,
    VerificationPendingDialogComponent,
    WayflessOrgComponent,
    PasswordExpiredDialogComponent,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
