<div mat-dialog-content class="dialog-content">
    <div class="dialog-title">
        <button class="cstm-icon-btn" [mat-dialog-close]="false" align="right">
            <em class="icon-cancel"></em>
        </button>
    </div>
    <p class="cstm-dialog-description">To complete account setup, we need you to reset your password.</p>
</div>
<mat-dialog-actions align="left" class="dialog-action">
    <button mat-raised-button cdkFocusInitial [mat-dialog-close]="true" color="primary">OK</button>
</mat-dialog-actions>