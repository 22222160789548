<div class="top-notification" *ngIf="showNotificationScreen">
	<div class="icon-container">
		<span class="icon-check-circle" *ngIf="isSuccess"></span>
		<span class="icon-error" *ngIf="!isSuccess"></span>
	</div>
	<div class="top-notification-container">
		<span role="alert">{{message}}</span>
	</div>
	<div>
		<button  id="closeNotification" class=" pull-right"  (click)="showNotificationScreen=false"><em class="icon-cancel" ></em></button>
	</div>
</div>
