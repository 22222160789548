import { Component } from '@angular/core';


@Component({
  selector: 'app-orcid-dialog-box',
  templateUrl: './orcid-dialog-box.component.html',
  styleUrls: ['./orcid-dialog-box.component.scss']
})
export class OrcidDialogBoxComponent {
}
