import { Component } from '@angular/core';
import { AuthapiService } from "../../../services/authapi/authapi.service";
import { AppState } from '../../../app.service';
import { isValidPassword } from '@tandfgroup/form-field-validator'
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'edit-password',
  templateUrl: 'edit-password.component.html',
  styleUrls: ['edit-password.component.scss']
})

export class EditPasswordComponent {
	
	username = '';
	currentPwd = '';
	newPwd = '';
	newReTypePwd = '';
	errorMessage = '';
	isError = false;
	isErrorCurrentPwd = false;
	isErrorNewPwd = false;
	isErrorNewReTypePwd = false;
	isPwdHintError = false;
	errorMessageCurrentPasswordEmpty = 'Current password cannot be empty.';
	errorMessageNewPasswordEmpty = 'New password cannot be empty.';
	errorMessageReTypePasswordEmpty = 'Please re-type your new password.';
	errorMessageCurrentNewSamePassword = 'New password cannot be same as old password.';
	errorMessageNewReTypeDoNotMatch = 'New password does not match with your re-type password.';
	updateInfoMessage = "Your password updated successfully ";
	showUdateInfoMessage = false;
	user: any = {};
	passwordHint: string = "Password must be at least 8 characters long and include at least one of each of 0-9, a-z, A-Z and symbol (e.g. ! # ? $).";


	orcidPasswordHint = "You are signed in using your ORCID Id.";
	orcidPasswordInfo = "To change your ORCID password, please visit <a href='https://orcid.org/signin' target='_blank'>https://orcid.org/signin</a>";
	samlPasswordHint = "You are signed in using an account affiliated to your institution.";
	samlPasswordInfo = "To change your account password, please visit your institution’s sign in page.";

	isOrcidLogin = false;
	isSamlLogin = false;
	subscription: Subscription;
	constructor(private authapiService: AuthapiService, public appState: AppState) { }

	ngOnInit() {
		this.subscription = this.appState.user$
			.subscribe((user) => {
				this.user = user;
				this.isOrcidLogin = !isEmpty(this.user) && !this.user.hasNativeLogin && this.user.orcidId && this.user.orcidId != '';
				this.isSamlLogin = !isEmpty(this.user) && !this.user.hasNativeLogin && (!this.user.orcidId || this.user.orcidId === '');
				this.username = this.user.username;
			});
	}

	updatePassword(): void {
		this.isError = false;
		this.isErrorCurrentPwd = false;
		this.isErrorNewPwd = false;
		this.isErrorNewReTypePwd = false;
		this.showUdateInfoMessage = false;
		this.isPwdHintError = false;

		if (!this.checkForErrors()) {
			this.authapiService.updatePassword(this.username, this.currentPwd, this.newPwd)
				.subscribe(
					() => {
						this.clearPasswordFields();
						this.showUdateInfoMessage = true;
					},
					(err) => {
						this.setErrorStateVariables(err.metadata);
					}
				)
		}
	}
	/* validate on new  password blur*/

	validatePassword() {
		if (this.newPwd && !isValidPassword(this.newPwd)) {
			this.isPwdHintError = true;
			this.isErrorNewPwd = true;

			this.isErrorNewReTypePwd = false;
			this.isErrorCurrentPwd = false;
			this.errorMessage = '';
		} else if (this.newReTypePwd && this.newPwd !== this.newReTypePwd) {
			this.isErrorNewReTypePwd = true;
			this.errorMessage = this.errorMessageNewReTypeDoNotMatch;
		}
	}
	private checkForErrors(): boolean {
		let isErrored = false;
		if (this.currentPwd === '') {
			isErrored = true;
			this.isErrorCurrentPwd = true;
			document.getElementById('inputCurrentPwd').focus();
			this.errorMessage = this.errorMessageCurrentPasswordEmpty;
		} else if (this.newPwd === '') {
			isErrored = true;
			this.isErrorNewPwd = true;
			document.getElementById('inputNewPwd').focus();

			this.errorMessage = this.errorMessageNewPasswordEmpty;
		} else if (this.newPwd && !isValidPassword(this.newPwd)) {
			isErrored = true;
			this.isErrorNewPwd = true;
			this.isPwdHintError = true;
			this.errorMessage = '';
			document.getElementById('inputNewPwd').focus();
		} else if (this.newReTypePwd === '') {
			isErrored = true;
			this.isErrorNewReTypePwd = true;
			document.getElementById('inputNewReTypePwd').focus();

			this.errorMessage = this.errorMessageReTypePasswordEmpty;
		} else if (this.currentPwd === this.newPwd) {
			isErrored = true;
			this.isErrorNewPwd = true;
			document.getElementById('inputNewPwd').focus();

			this.errorMessage = this.errorMessageCurrentNewSamePassword;
		} else if (this.newReTypePwd !== this.newPwd) {
			isErrored = true;
			this.isErrorNewReTypePwd = true;
			document.getElementById('inputNewReTypePwd').focus();

			this.errorMessage = this.errorMessageNewReTypeDoNotMatch;
		}
		console.log("err", this.errorMessage);
		return isErrored;
	}

	private clearPasswordFields(): void {
		this.currentPwd = '';
		this.newPwd = '';
		this.newReTypePwd = '';
	}

	private setErrorStateVariables(errorMetadata: any) {
		if (errorMetadata) {
			this.errorMessage = errorMetadata.message.value;
			switch (errorMetadata.message.key) {
				case "NEW_PASSWORD":
					this.isErrorNewPwd = true;
					break;
				case "OLD_PASSWORD":
					this.isErrorCurrentPwd = true;
					break;
				case "EMAIL_ADDRESS":
				case "UNEXPECTED_ERROR":
					this.isError = true;
					break;
				default:
					break;
			}
		}
	}

	ngOnDestroy() {
		// prevent memory leak by unsubscribing
		this.subscription?.unsubscribe();
	}

	onKeydownEvent(event) {
		if (event.srcElement.id === 'inputCurrentPwd') {
			this.isErrorCurrentPwd = false;
		}
		if (event.srcElement.id === 'inputNewPwd') {
			this.isErrorNewPwd = false;
			this.isPwdHintError = false;
		}
		if (event.srcElement.id === 'inputNewReTypePwd') {
			this.isErrorNewReTypePwd = false;
		}
	}
}
