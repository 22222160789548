// ANGULAR
import { Component, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
//Third Party Lib
import { sortBy } from 'lodash-es';

import { LoginRegisterService } from './login-register.service';
import { AppState } from '../../app.service';
import { UrlConfig } from '../../../url-config';
import { AuthapiService } from '../../services/authapi/authapi.service';
import { PrivilegesService } from '@tandfgroup/platform-component-common-privileges-service';
import { VerificationPendingDialogComponent } from '../../../app/components/ui/verification-pending-dialog/verification-pending-dialog.component';

import { environment } from '../../../environments/environment';
import { NotificationConstants } from '../../utils/notification-utils/notification-constants';
import { NotificationVailidityChecker } from '../../utils/notification-utils/notification-validity-checker';
import { isValidEmail } from '@tandfgroup/form-field-validator'
import { MatDialog } from '@angular/material/dialog';
import { PasswordExpiredDialogComponent } from '../../../app/components/ui/password-expired-dialog/password-expired-dialog.component';

const baseURL: any = new UrlConfig().getBaseApiUrl();

@Component({
  selector: 'login-register',
  templateUrl: 'login-register.component.html',
  styleUrls: ['login-register.component.scss'],
  providers: [LoginRegisterService],
  encapsulation: ViewEncapsulation.None
})
export class LoginRegisterComponent {

  /*variables for notification*/
  showTopNotification = false;
  notificationMsg = '';
  isSuccess = false;
  loading = false;

  // flag to disable ezproxy url
  isEzProxy = false;

  // flag to store remeber me option
  isRememberMe = false;

  localHost = false;
  invalidMailValue: boolean = false;
  invalidPassValue: boolean = false;
  idToken = null;
  blurEmailError: boolean = false;
  blurPasswordError: boolean = false;
  lastkeydown1: number = 0;
  selectedItemInx = 0;
  inputScreenReaderText: string = '';
  userList1: any;
  userData: any;
  isOffice365Login: boolean = false;
  title: string;
  resendActionText: string;
  message: string;
  viewPswd: string = 'icon-eye-blocked';
  localState: any = {
    email: '',
    emailPattern: /.*/,
    fullName: '',
    password: '',
    institution: '',
    authorize: '',
    isInvalidEmail: '',
    isInvalidPassword: '',
    isUserBlocked: '',
    isIncorrectEmail: '',
    isIncorrectPassword: '',
    isUnexpectedError: '',
    isCookieBlocked: '',
    errorMessage: '',
    errorMessagePassword: '',
    clientId: null,
    institutionId: '',
    metadata: '',
    selectedOrg: {},
    orgs: [],
    orgList: [],
    showSocialMediatag: false,
    loginSection: true,
    shibSection: false,
    login_window_title: true,
    hideAppName:false,

    orcid: baseURL + 'user/auth/orcid',
    facebook: baseURL + 'user/auth/facebook',
    google: baseURL + 'user/auth/google',
    linkedin: baseURL + 'user/auth/linkedin',
    authorizeAction: baseURL + 'user/auth/authorize',
    samlLogin: baseURL + 'user/auth/saml/',
    officeLogin: baseURL + 'user/auth/office',
    result: jQuery('main').addClass('main-image'),
    brand: 'ubx',
    clientConfig: null,
    incorrectPasswordCount: 0,
    incorrectPasswordUsername: "",
    state: '',
    orcidVisibilitySettingUrl: "",
    orcidSignInUrl: ""
  };
  updateInfoMessage = NotificationConstants.MESSAGE;
  showUdateInfoMessage = NotificationVailidityChecker.compareDate(new Date(NotificationConstants.FROM_DATE), new Date(NotificationConstants.TO_DATE));
  disableContinueButton: boolean = true;

  @ViewChild('loginButton', { static: true }) loginButton: ElementRef;
  @ViewChild('ngAutoComplete', { static: true }) nguiWrapper: ElementRef;
  @ViewChild('searchInstituteInput', { static: true }) searchInstituteInput: ElementRef;
  @ViewChild('inputText', { static: true }) inputText: ElementRef;

  // This property apparently affect the email label. But why?
  public isModalNotActive: boolean = true;

  constructor(private loginRegister: LoginRegisterService, private appState: AppState, private _route: Router,
    private authapiService: AuthapiService, private privilegesService: PrivilegesService,
    private dialog: MatDialog, private route: ActivatedRoute) {
    this.title = "Verification pending";
    this.resendActionText = "RESEND EMAIL";
    this.message = "Your email address has not been verified yet. Please check your inbox for the verification link." +
      " If you have not received it, click on resend email";
  }

  async ngOnInit() {
    this.localHost = /localhost/.test(location.href);

    //EzProxy urls have url texts as 'taylorfrancis-com' instead of 'taylorfrancis.com', hence it should disable recapctha only for EzProxy
    this.isEzProxy = /taylorfrancis-com/.test(location.href);

    if(Object.keys(this.appState.get('queryParams')).indexOf('brand') !== -1){
    this.localState.brand = this.appState.get('queryParams')['brand'];
    }
    else{
      this.localState.brand = ''
    }

    this.decideUIbasedOnAppConfig();

    if (Object.keys(this.appState.get('queryParams')).length > 0) {

      /*When user clicks on verification link in the mail ,
      it goes to verify-registration component and after successful verification its redirected to login page
      with query parameter verified=true or alreadyVerified = true and top-notification is shown based query param */
      if ((this.appState.get('queryParams')['verified'])) {
        this.showTopNotification = true;
        this.isSuccess = true;
        this.notificationMsg = 'Your email address has been successfully verified. Log in to proceed.';
      }
      if ((this.appState.get('queryParams')['alreadyVerified'])) {
        this.showTopNotification = true;
        this.isSuccess = false;
        this.notificationMsg = 'Your email address has already been verified. Please log in to your account.';
      }
      this.localState.clientId = this.appState.get('queryParams')['client_id'];
      this.localState.authorize = Boolean(this.appState.get('queryParams')['authorize']);
      this.localState.state = this.appState.get('queryParams')['state'];
    }
    if (Object.keys(this.appState.get('user')).length > 0) {
      await this.redirectToNextPage();
    }

    const clientConfig = this.appState.getClientConfig();

    if(clientConfig.loginConfig.hasOwnProperty("login_window_title")){
      this.localState.login_window_title = clientConfig.loginConfig.login_window_title;
    }

    if(clientConfig.hasOwnProperty("hideAppName")){
      this.localState.hideAppName = clientConfig.hideAppName;
    }

    this.localState.orcidVisibilitySettingUrl = new UrlConfig().getOrcidVisibilitySettingUrl();    
    this.localState.orcidSignInUrl = new UrlConfig().getOrcidSignInUrl();

  }

  ngAfterViewInit() {
    $(() => {
      (<any>$("[data-toggle='popover']")).popover();
    })
  }

  resendverifyLink(userEmail?: string) {
    console.log("resend verify link called..")
    let user = this.appState.get('user');
    this.showTopNotification = false;
    let email = user?.email && user.email.length > 0 ? user.email : userEmail;
    console.log('Params',email, this.localState.clientId, this.localState.brand, this.localState.clientConfig?.name)
    if (email) {
      this.authapiService.resendverifyLink(email, this.localState.clientId, this.localState.brand, this.localState.clientConfig?.name)
        .subscribe((data) => {
          this.notificationMsg = 'Verification link has been resent to ' + email;
          this.isSuccess = true;
          this.showTopNotification = true;
        }, err => {
          this.isSuccess = false;
          this.showTopNotification = true;
        });
    } else {
      console.log(" No user email found");
    }

  }

  /**
   * Login Method, For When The User Wants to Login
   * @param {any} localState This is the current version of the local state defined above.
   */
  login() {
    this.localState.isInvalidEmail = false;
    this.localState.isIncorrectEmail = false;
    this.localState.isIncorrectPassword = false;
    this.localState.isUnexpectedError = false;
    this.showTopNotification = false;

    this.localState.email = this.localState.email.trim();

    if (!this.localState.email) {
      this.localState.isInvalidEmail = true;
      this.invalidMailValue = true;
      this.focus(document.getElementById('inputEmail'));
      this.localState.errorMessage = "Email address cannot be empty";
      return;
    } else if (!isValidEmail(this.localState.email)) {
      this.localState.isInvalidEmail = true;
      this.invalidMailValue = true;
      this.focus(document.getElementById('inputEmail'));
      this.localState.errorMessage = "Must be an email address.";
      return;
    } else if (this.redirectToOffice365IfInformaDomain(this.localState.email)) {
      this.processUserRedirect(this, null, true);
      return;
    } else if (!this.localState.password) {
      this.localState.isIncorrectPassword = true;
      this.invalidPassValue = true;
      this.focus(document.getElementById('inputPassword'));
      this.localState.errorMessagePassword = "Password cannot be empty";
      return;
    }
    this.loading = true;

    return this.loginRegister.login(this.localState.email, this.localState.password, this.isRememberMe, this.localState.clientId, this.localState.state)
      .subscribe(
        user => {
          (async () => {
            // user login flow if validated
            this.appState.user$.next(user);
            if(user?.isValidated){
              await this.appState.loadUserProfileComponent(true);
            }
            await this.userLoginFlowIfValidated(user);
          })();
        },
        err => {
          console.log("error is", err);
          this.loading = false;
          this.setErrorStateVariables(err.metadata);
        }
      );
  }

  onClickRedirectToHomelink() {
    window.open(this.loginRegister.redirectToOaHomelink());
  }

  private focus(ele: HTMLElement) {
    ele?.focus();
  }

  private async userLoginFlowIfValidated(user: any) {
    if (user?.isValidated && user.mFAEnabled && !user.mFAAuthenticated) {
      await this.redirectToMultiFactorAuth(user);
    } else if (user.consentGiven != undefined && !user.consentGiven && user.isValidated) {
      await this.redirectToOrcidShibboleth(user);
    } else if (user?.isValidated && user.hasAcceptedTerms) {
      this.getTheUsersLatestInfoAndRedirect(user);
    } else if (user?.isValidated && !user.hasAcceptedTerms) {
      this.loading = false;
      await this._route.navigate(['/accept-terms'], { queryParams: { userId: user._id } });
    } else {
      this.loading = false;
      this.resendModal();
    }
  }

  private async redirectToMultiFactorAuth(user: any) {
    this.loading = false;
    const countryExists = user.address?.country?.length >= 2 ? user.address.country : null;
    if (this.localState.authorize) {
      await this._route.navigate(
        ['/multi-factor-auth'],
        { queryParams: { authorize: this.localState.authorize, client_id: this.localState.clientId, userId: user._id, countryExists, state: this.localState.state } });
    } else {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { userId: user._id, client_id: this.localState.clientId, countryExists, state: this.localState.state } });
    }//check to show consent page
  }

  private async redirectToOrcidShibboleth(user) {
    this.loading = false;
    const countryExists = user.address?.country?.length >= 2 ? user.address.country : null;
    if (this.localState.authorize) {
      await this._route.navigate(
        ['/orcid-shibboleth'],
        { queryParams: { authorize: this.localState.authorize, client_id: this.localState.clientId, userId: user._id, "isTnfLogin": true, countryExists, state: this.localState.state, optOut: user.optOut } });
    } else {
      await this._route.navigate(
        ['/orcid-shibboleth'],
        { queryParams: { userId: user._id, "isTnfLogin": true, client_id: this.localState.clientId, countryExists, state: this.localState.state, optOut: user.optOut } });
    }
  }

  private getTheUsersLatestInfoAndRedirect(user: any) {
    if (user?.accessToken[0]?.idToken) {
      this.idToken = user.accessToken[0].idToken;
    } else if (user?.accessToken[1]?.idToken) {
      this.idToken = user.accessToken[1].idToken;
    }
    let _self = this;
    _self.appState.loggedInUser().subscribe(
      userInfo => {
        if (userInfo) {
          _self.privilegesService.getRolesAndPrivileges(_self.idToken, environment.envName, userInfo.accessDetails)
            .then(() => {
              _self.processUserRedirect(_self, userInfo);
            }).catch((err: Error) => {
              _self.processUserRedirect(_self, userInfo);
              console.log('Unauthorized Access', err);
            });
        }
        else {
          console.log("User not found");
        }
      },
      err => {
        console.log("In error of self ", err.data, err.status, "Whole error ", err);
        this.loading = false;
        this.setErrorStateVariables(err.metadata);
      });
  }


  processUserRedirect(_self: any, user: any, isInformaDomain?: boolean): void {
    _self.loading = false;
    if ((_self.localState.authorize || _self.localState.clientId) && !isInformaDomain ) {
      console.log("inside authorize-->",_self.localState.authorize, _self.localState.clientId);
      const authorizeParams = _self.appState.get('authorizeParams');
      const authorizeQueryParams = Object.keys(authorizeParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(authorizeParams[k])}`).join('&');
      this.setHref(_self.localState.authorizeAction + "?" + authorizeQueryParams);
    } else if (!user && isInformaDomain) {
      this.officeLogin();
    } else {
      _self.setUserAndRedirect(user);
    }
  }

  redirectToOffice365IfInformaDomain(email: string) {
    let domains = this.appState.get('domains');
    const emailParts = email.toLowerCase().trim().split('@');
    this.isOffice365Login = false;
    if (emailParts.length === 2) {
      if (domains && domains.length > 0 && domains.indexOf(emailParts[1]) > -1) {
        this.blurPasswordError = false;
        this.isOffice365Login = true;
        return this.isOffice365Login;
      }
    }
    return false;
  }

  async setUserAndRedirect(user: any): Promise<void> {
    // Push to user stream
    this.appState.user$.next(user);
    // Set user property
    this.appState.set('user', user);
    await this.redirectToNextPage();
  }

  orgList(): void {
    this.localState.loginSection = false;
    this.localState.shibSection = true;
    this.privilegesService.setRoles(null);
    this.privilegesService.setPrivileges(null);
    if (localStorage.getItem("recentlySelectedOrgs")) {
      this.localState.orgs = JSON.parse(localStorage.getItem("recentlySelectedOrgs"));
    }
    this.authapiService.getOrgList(this.localState.clientId, null, 'true', 'small').subscribe(
      res => {
        const orgList = res.length? res : res.orgList
        if (orgList) {
          this.localState.orgs = (<Array<any>>orgList).map((org: any) => {
            // name for org could be missing. show id if name not found.
            let name = org.name ? org.name : org._id;
            return { "id": org._id, "value": name };

          });
          // sort orgs albhabetically ascending
          this.localState.orgs = sortBy(this.localState.orgs, "value");

          this.localState.orgsList = (<Array<any>>orgList).map((org: any) => {
            return org;
          });
        }
      },
      err => {
        this.setErrorStateVariables(err.metadata);
      }
    );
    window.scrollTo({ top: 0 });
  }

  orcidLogin(): void {
    let redirectUrl = `${this.localState.orcid}${this.localState.authorize ? '?authorize=true' : ''}`
    if (this.localState.clientId) {
      redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}clientId=${this.localState.clientId}`
    }
    this.setHref(redirectUrl);
  }

  orcidSignup(): void {
    let redirectUrl = `${this.localState.orcid}?show_login=false${this.localState.authorize ? '&authorize=true' : ''}`
    if (this.localState.clientId) {
      redirectUrl += `&clientId=${this.localState.clientId}`
    }
    this.setHref(redirectUrl);
  }

  officeLogin(): void {
    let redirectUrl = `${this.localState.officeLogin}${this.localState.authorize ? '?authorize=true' : ''}`
    if (this.localState.clientId) {
      redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}clientId=${this.localState.clientId}`
    }
    this.setHref(redirectUrl);
  }

  private setHref(url: string) {
    window.location.href = url;
  }

  showLogin(): void {
    this.localState.loginSection = true;
    this.localState.shibSection = false;
    this.selectedItemInx = 0;
    window.scrollTo({ top: 0 });
  }

  emailFocus(): void {
    this.localState.isInvalidEmail = false;
  }

  tabFocusChange(): void {
    this.localState = {
      email: '',
      fullName: '',
      password: '',
      institution: '',
      isInvalidEmail: '',
      isInvalidPassword: '',
      isCookieBlocked: '',
      isIncorrectEmail: '',
      isIncorrectPassword: '',
      clientId: null
    };
  }

  async forgotPassword() {
    await this._route.navigate(["/reset-password"], { queryParams: { reset: true }, queryParamsHandling: 'merge' });
  }


  invalidLoginEntry() {
    if (this.localState.isInvalidEmail) {
      return true;
    }
    else {
      return false;
    }
  }

  invalidPasswordEntry() {
    return !!this.localState.isIncorrectPassword;
  }

  validateEmailBlur() {
    setTimeout(() => {
      if (!this.localState.email) {
        this.localState.isInvalidEmail = true;
        this.localState.errorMessage = "Email address cannot be empty";
        return;
      }

      if (!isValidEmail(this.localState.email)) {
        this.localState.isInvalidEmail = true;
        this.localState.errorMessage = "Must be an email address.";
      }
    }, 500);
  }

  validateEmail() {
    if (this.localState.email.length > 0 && !isValidEmail(this.localState.email)) {
      this.localState.isInvalidEmail = true;
      this.localState.errorMessage = "Must be an email address.";
    }

    if (isValidEmail(this.localState.email)) {
      this.localState.isInvalidEmail = false;
    }
  }
  validatePassword() {

    if (this.localState.password.length > 0) {
      this.blurPasswordError = false;
    }
  }

  validatePasswordBlur() {

    if (!this.localState.password) {
      this.blurPasswordError = true;
      this.localState.errorMessagePassword = "Password cannot be empty";

    }
    else if (this.localState.isIncorrectPassword || this.localState.isIncorrectEmail || this.localState.isInvalidPassword) {
      this.blurPasswordError = true;
      if (this.localState.incorrectPasswordCount == 0) { this.localState.incorrectPasswordCount++; }
      this.localState.errorMessagePassword = this.getErrorMessage(null);
    }
    else if (this.localState.isUserBlocked) {
      this.blurPasswordError = true;
    } else {
      this.blurPasswordError = false;
    }
  }

  async navigateToSignUp() {
    let redirectUrl = "/sign-up";
    if (this.localState.clientId) {
      redirectUrl += "?authorize=true" + "&client_id=" + this.localState.clientId + "&state=" + this.localState.state;
    }
    if (this.localState.brand) {
      redirectUrl += "&brand=" + this.localState.brand;
    }
    await this._route.navigateByUrl(redirectUrl);
  }

  displayOrgNameFn(data: any): string {
    return data?.value ? data.value : '';
  }

  onSelectInstitute(data: any) {
    console.log("data   ", data);
    if (data !== "") {
      this.disableContinueButton = false;
      let orgSelected = this.localState.orgsList.find(org => {
        return (org._id === data.id || org.name === data.id);
      });
      this.localState.selectedOrg = orgSelected;
    } else {
      this.disableContinueButton = true;
    }
  }

  onChangeFun(data: any) {
    this.disableContinueButton = true;
  }

  inputClearedFun(data: any) {
    this.disableContinueButton = true;
  }

  /**
  * when user select Shibboleth or OpenAthens login option
  * and search respective institution on the search box.
  * On selecting institution this function will redirect the user to
  * user/auth/saml/<institutionId>?clientId=<clientId>
  */
  public samlLogin(): void {

    /**
     * In case of Saml login this.localState is an selected organization object.
     * Every org has identity provides called idps.
     * Idps will have the saml information of the organization
     * If selected org has metadata then only it should
     * redirect to  user/auth/saml/<institutionId>?clientId=<clientId>
     * Else fail silently
     */
    if (this.localState?.selectedOrg?._id) {
      this.setHref(this.localState.samlLogin + this.localState.selectedOrg._id +
        "?clientId=" + this.localState.clientId);
    }
  }

  decideUIbasedOnAppConfig() {
    const clientConfigData = this.appState.getClientConfig();
    if (clientConfigData) {
      try {
        this.localState.clientConfig = clientConfigData;
      } catch (error) { this.localState.clientConfig = null; }
    }
    if (this.localState.clientConfig) {
      const flow = this.appState.get('queryParams')['flow'];
      const journalName = this.appState.get('queryParams')['journal'];
      this.localState.clientConfig.subFlowText = this.getTextBasedOnFlow(flow);

      if (this.localState.clientConfig.subFlowText) {
        this.localState.clientConfig.subFlowText = 'You are ' + this.localState.clientConfig.subFlowText;
        this.localState.clientConfig.subFlowJournal = journalName;
      }
    }
  }

  private getTextBasedOnFlow(flow: string) {
    if (flow == 'transfer') {
      return 'starting a transfer to ';
    } else {
      return flow == 'new' ? 'entering the submission site for ' : null;
    }
  }
  /**
   * Sets The Error Variables Based On the Error Response.
   * Checks whether password was incorrect or Email
   * @param {any} errorMetadata Error Response Sent Back By The API
   */
  private setErrorStateVariables(errorMetadata: any) {
    if (errorMetadata.message) {
      this.localState.errorMessage = errorMetadata.message.value;
      this.localState.errorMessagePassword = errorMetadata.message.value;
      if (this.localState.incorrectPasswordCount > 0 && this.localState.incorrectPasswordUsername != this.localState.email) {
        this.localState.incorrectPasswordCount = 0;
      }
      this.localState.incorrectPasswordCount++;
      this.localState.incorrectPasswordUsername = this.localState.email;
      const errMsg = this.getErrorMessage(errorMetadata.message.key);
      switch (errorMetadata.message.key) {
        case "PASSWORD":
          this.localState.isInvalidPassword = true;
          this.invalidPassValue = true;
          this.localState.errorMessagePassword = errMsg;
          this.focus(document.getElementById('inputPassword'));
          break;
        case "USER_BLOCKED":
          this.localState.isInvalidPassword = false;
          this.localState.isUserBlocked = true;
          this.localState.isIncorrectEmail = false;
          this.localState.errorMessagePassword = errMsg;
          document.getElementById('inputPassword').focus();
          break;
        case "EMAIL_ADDRESS":
          this.localState.isIncorrectEmail = true;
          this.localState.errorMessagePassword = errMsg;
          this.focus(document.getElementById('inputPassword'));
          break;
        case "UNEXPECTED_ERROR":
          this.localState.isUnexpectedError = true;
          this.localState.errorMessagePassword = errMsg;
          this.focus(document.getElementById('inputPassword'));
          break;
        case "PASSWORD_EXPIRED":
          this.openPasswordExpiredDialog()
          break;
        default:
          break;
      }
    } else {
      console.log("errorMetadata message missing using status");
      if (errorMetadata.status == 'failure') {
        console.log("Setting cookieBlocked field");
        this.localState.isCookieBlocked = true;
        this.localState.errorMessage = "Third party cookie blocked";
      }
    }
  }

  private getErrorMessage(key) {
    let errorMsg = "You have made " + this.localState.incorrectPasswordCount + " unsuccessful attempt(s). A maximum of 3 login attempts are permitted. The password is case-sensitive. To generate a new password, use the ‘Forgot Password’ option.";

    if (this.localState.incorrectPasswordCount >= 3 || key == "USER_BLOCKED") {
      let queryParm = this.getHref().substring(this.getHref().lastIndexOf('?') + 1);
      queryParm = queryParm ? '&' + queryParm : '';
      errorMsg = "Your account is locked as you have exceeded the maximum number of invalid attempts. Please <a class='forgot-pwd' href=#/reset-password?reset=true" + queryParm + ">reset</a> your password to unlock your account";
    }

    return errorMsg;
  }

  public getHref() {
    return window.location.href;
  }

  private async redirectToNextPage() {
    if (!this.appState.get('user').isValidated) {
      console.error("verification link has been sent to email id please verify");
    } else {
      await this.appState.loadUserProfileComponent(true);
      await this._route.navigate(["/accounts"], { replaceUrl: true });
    }
  }

  // Put up the modal dialog box asking the user if he wants to resend the email.
  private resendModal(): void {
    this.isModalNotActive = false;
    const dialogRef = this.dialog.open(VerificationPendingDialogComponent, { width: "525px", disableClose: true, panelClass: 'verify-dialog-section' });

    dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.resendverifyLink(this.localState.email);
        this.isModalNotActive = true
      }
    })
  }

  private openPasswordExpiredDialog(){
    const dialogRef = this.dialog.open(PasswordExpiredDialogComponent, { width: "525px", panelClass: 'password-expired-dialog' });
    dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.forgotPassword();
      }
    })
  }
}

/**
 * This function will check for non null and non "null" string in param
 * @param {null | string | Array}
 * @return {Boolean}
  */
function nonNull(param: null | string | Array<string>): boolean {
  return param && param !== "null";
}
