import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppState } from '../../app.service';
import { UrlConfig } from '../../../url-config';

const urlConfig = new UrlConfig();
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private appState: AppState) { }

  bottomContent = `<p class="footer-bottom-content">Registered in England & Wales No. 3099067<br> 5 Howick Place | London | SW1P 1WG </p>`;
  copyRight = `© ${this.getCurrentYear()} Informa UK Limited`
  socialFontIcons = [
    {
      name: '',
      url: 'https://www.facebook.com/TaylorandFrancisGroup/',
      image: 'icon-facebook',
      showImageOnly: true,
    },
    {
      name: '',
      url: 'https://x.com/tandfonline',
      image: 'icon-twitter',
      showImageOnly: true,
    },
    {
      name: '',
      url: 'https://www.youtube.com/user/TaylorandFrancis',
      image: 'icon-youtube',
      showImageOnly: true,
    },
    {
      name: '',
      url: 'https://www.instagram.com/tandfresearch',
      image: 'icon-instagram',
      showImageOnly: true,
    },
    {
      name: '',
      url: 'https://linkedin.com/company/taylor-&-francis-group',
      image: 'icon-linkedin',
      showImageOnly: true,
    }
  ];
  policies = [
    {
      name: 'Privacy Policy',
      url: 'https://informa.com/privacy-policy',
      image: '',
      showImageOnly: false,
    },
    {
      name: '• Terms & Conditions',
      url: 'https://www.taylorfrancis.com/terms-and-conditions',
      image: '',
    },
    {
      name: '• Cookie Policy',
      url: 'https://rp.tandfonline.com/static/cookie-policy',
      image: '',
    },
    {
      name: '• Accessibility',
      url: 'https://taylorandfrancis.com/about/corporate-responsibility/accessibility-at-taylor-francis/',
      image: '',
    },
    {
      copyRight: 'Copy right',
      title: `© ${this.getCurrentYear()} Informa UK Limited`,
      image: '',
    },
  ];

  public getConfig(): Observable<any> {
    let config = this.appState.getClientConfig();
    return of({
      currentTheme: {
        baseurl: config ? config.baseUrl : ' ',
        headerLogo: "assets/img/tfgroup-logo.svg",
        footerLogo: 'assets/img/tfgroup-logo-white.svg',
        footer: {
          categories: this.getUBXCategories(), // The links on the footer
          bottomContent: this.bottomContent, // The last line of the footer
          copyRight: this.copyRight
        }
      }
    });
  }

  private getUBXCategories(): Map<string, any> {
    const categoriesMap = new Map<string, Array<any>>(); //Map of Category header and category links.
    categoriesMap.set('', this.policies);
    categoriesMap.set('Connect with us', this.socialFontIcons);
    return categoriesMap;
  }

  private getCurrentYear(): number {
    return new Date().getFullYear();
  }
}