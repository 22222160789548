/**
 * Authenticated user service
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConfig } from '../../../url-config';

@Injectable({ providedIn: 'root' })
export class AccountsService {
  private userId: string = '';
  private baseURL: string = new UrlConfig().getBaseUrl();
  private updateAccountURL = `${this.baseURL}/accounts`;
  private verifyPasswordURL = `${this.baseURL}/auth/user/auth/verifyPassword`

  constructor(private http: HttpClient) { }

  updateAccount(id: string, updateData): Observable<any> {
    return this.http.patch(`${this.updateAccountURL}/${id}`, updateData);
  }

  markForDeletion(username: string): Observable<any> {
    return this.http.patch(`${this.updateAccountURL}/markfordeletion/${username}`, {});
  }

  verifyPassword(username: string, password: string): Observable<any> {
    return this.http.post(`${this.verifyPasswordURL}`, { username: username, password: password });
  }

  updateEmail(username: string, updateData): Observable<any> {
    return this.http.patch(`${this.updateAccountURL}/email/${username}`, updateData);
  }

  unDoEmailUpdate(username: string, updateData): Observable<any> {
    return this.http.delete(`${this.updateAccountURL}/email/${username}`, updateData);
  }

}
