import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountsService } from "../../../services/accounts-api/accounts-api.service";
import { AuthapiService } from "../../../services/authapi/authapi.service";
import { AppState } from '../../../app.service';
import { nameValidator, urlValidator, isValidEmail } from '@tandfgroup/form-field-validator'
@Component({
  selector: 'my-account',
  templateUrl: 'my-account.component.html',
  styleUrls: ['my-account.component.scss']
})
export class MyAccountComponent {
  displayName = '';
  firstName = '';
  lastName = '';
  email = '';
  username = '';
  institution = '';
  userId = '';
  user: any = {};
  isEmailEditable = false;
  isInstitutionEditable = false;
  isInvalidFirstName = false;
  isInvalidLastName = false;
  isInvalidEmail = false;
  isInvalidInstitution = false;
  errorMessage = '';
  errorMessageFirstName = '';
  errorMessageLastName = '';
  updateInfoMessageDetailsSave = "Your name updated successfully";
  updateInfoMessageBackUpCodesGenerated = "Backup codes downloaded successfully ";
  updateInfoMessage = this.updateInfoMessageDetailsSave;
  showUdateInfoMessage = false;
  mFAEnabled = false;
  editDisabled: boolean = false;

  private subscription: Subscription;

  constructor(
    private accountsService: AccountsService, private authService: AuthapiService,

    // Needs to be public for testing.
    public appState: AppState
  ) {
  }

  ngOnInit() {
    this.subscription = this.appState.user$.subscribe((user) => {
      console.log('user', user)
      this.user = user;
      this.userId = this.user._id;
      this.setNames();
      this.email = this.user.email;
      this.username = this.user.username;
      this.institution = this.user.organization;
      this.mFAEnabled = this.user.mFAEnabled || false;
      this.editDisabled = this.user.userType == 'office365_user';
      console.log('firstName', this.firstName, 'lastName', this.lastName, 'displayName', this.displayName);
    });
  }

  setNames(){
    this.user.firstName = this.user.firstName ? this.user.firstName.trim() : '';
    this.user.lastName = this.user.lastName ? this.user.lastName.trim() : '';
    if(this.user.firstName && this.user.lastName) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
    }
    else if(this.user.displayName){
      if(this.user.displayName.includes(',')){
        let displayNameArr = this.user.displayName.split(',').map(e => {return e.trim()});
        this.firstName = displayNameArr[1];
        this.lastName = displayNameArr[0];
        this.displayName = this.firstName + ' ' + this.lastName;
      }
      else {
        let displayNameArr = this.user.displayName.split(' ');
        if (displayNameArr.length === 1) {
          this.firstName = this.user.displayName;
          this.lastName = this.user.displayName;
        }
        else if (displayNameArr.length === 2) {
          this.firstName = displayNameArr[0];
          this.lastName = displayNameArr[1];
        }
        else if (displayNameArr.length > 2) {
          this.firstName = displayNameArr[0];
          this.lastName = displayNameArr.slice(1).join(' ');
        }
      }
    }
    else{
      this.firstName = this.user.firstName ? this.user.firstName : '';
      this.lastName = this.user.lastName ? this.user.lastName : '';
      this.displayName = (this.firstName + ' ' + this.lastName).trim();
    }
    this.displayName = this.user.displayName
  }

  updateAccount(): void {
    this.isInvalidFirstName = false;
    this.isInvalidLastName = false;
    this.isInvalidEmail = false;
    this.isInvalidInstitution = false;
    this.showUdateInfoMessage = false;

    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();
    this.email = this.email.trim();

    if (!this.firstName && !this.lastName) {
      this.isInvalidFirstName = true;
      this.errorMessageFirstName = "First Name cannot be empty";
      document.getElementById('inputFirstName').focus();
      this.isInvalidLastName = true;
      this.errorMessageLastName = "Last Name cannot be empty";
    }
    if (!this.firstName) {
      this.isInvalidFirstName = true;
      this.errorMessageFirstName = "First Name cannot be empty";
      document.getElementById('inputFirstName').focus();
    }
    else if (!this.lastName) {
      this.isInvalidLastName = true;
      this.errorMessageLastName = "Last Name cannot be empty";
      document.getElementById('inputLastName').focus();
    }
    else if (this.isEmailEditable && !isValidEmail(this.email)) {
      this.isInvalidEmail = true;
      this.errorMessage = "Must be an email address.";
      document.getElementById('isInvalidEmail').focus();
    }
    this.validateName(this.firstName, 'firstName');
    this.validateName(this.lastName, 'lastName');
    if (!this.isInvalidFirstName && !this.isInvalidLastName && !this.isInvalidEmail) {
      this.accountsService.updateAccount(this.userId, { firstName: this.firstName, lastName: this.lastName, displayName: this.firstName + ' ' + (this.lastName ? this.lastName : this.firstName) })
        .subscribe(
          (response) => {
            this.appState.user$.next(response);
            this.appState.set('user', response);
            this.updateInfoMessage = this.updateInfoMessageDetailsSave;
            this.showUdateInfoMessage = true;
            setTimeout(() => {
              document.getElementById('closeNotification').focus()
            }, 100);
          },
          (err) => {
            console.log('Unable to update user', err);
            this.isInvalidFirstName = true;
            this.isInvalidLastName = true;
            this.errorMessage = err.message;
          }
        );
    }

  }

  validateName(name: string, nameParameter: string) {
    if (this.detectURL(name) || !this.detectSpecialCharacters(name)) {
      if (nameParameter === 'firstName') {
        this.isInvalidFirstName = true;
        this.errorMessageFirstName = 'First Name should not contain URL or empty space';
      }
      else if (nameParameter === 'lastName') {
        this.isInvalidLastName = true;
        this.errorMessageLastName = 'Last Name should not contain URL or empty space';
      }
    }
  }

  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    this.subscription?.unsubscribe();
  }
  onKeydownEvent(event: any) {
    if (event.srcElement.id === 'inputFirstName') {
      this.isInvalidFirstName = false;
    }
    if (event.srcElement.id === 'inputLastName') {
      this.isInvalidLastName = false;
    }
    if (event.srcElement.id === 'isInvalidEmail') {
      this.isInvalidEmail = false;
    }
    if (event.srcElement.id === 'inputInstitution') {
      this.isInvalidInstitution = false;
    }
  }


  private detectURL(str: string) {
    return urlValidator(str)
  }

  private detectSpecialCharacters(str: string) {
    return nameValidator(str)
  }
}