<!--
`themed-color` is the preferred class for themed foreground color.
Some themes may continue to use the older `text-color`, so we supply that too.
-->

<top-notification [message]="notificationMsg" [isSuccess]="true" *ngIf="resentEmail"></top-notification>

<div class="signUpCommonClass" role="region" aria-label="Sign up">
   <div *ngIf="!emailSentScreen" id="app-header-title">
      <h1 class="app-name text-color themed-text">{{clientDetails?.name}}</h1>
      <p class="app-title text-color themed-text">{{clientDetails?.title}}</p>
   </div>
   <div class="signup-section" *ngIf="!emailSentScreen">
      <div class="signup-sub-section">
         <div class="sign-up-container">
            <div class="sign-up-info">
               <span>Create account</span>
            </div>
            <form class="form-bowl" autocomplete="off">
               <div class="form-group is-empty label-floating mrClass">
                  <span for="firstName" class="form-label">First Name</span>
                  <input type="text" class="form-control" id="firstName"
                     [attr.aria-invalid]="returnInvalidName('firstName')" [(ngModel)]="firstName" autocomplete="off"
                     name="firstName" [attr.aria-describedby]="'firstNameError'"
                     [ngClass]="{'inputError':isIncorrectFirstName}" (blur)="validateNameBlur('firstName')"
                     (input)="validateName('firstName')">

                  <div class="errorMsg" [ngClass]="isIncorrectFirstName===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position" id="firstNameError">{{
                        errorMsgFirstName }}</span>
                  </div>
               </div>


               <div class="form-group is-empty label-floating mrClass">
                  <span for="lastName" class="form-label">Last Name</span>
                  <input type="text" class="form-control" id="lastName"
                     [attr.aria-invalid]="returnInvalidName('lastName')" [(ngModel)]="lastName" autocomplete="off"
                     name="lastName" [attr.aria-describedby]="'lastNameError'"
                     [ngClass]="{'inputError':isIncorrectLastName}" (blur)="validateNameBlur('lastName')"
                     (input)="validateName('lastName')">

                  <div class="errorMsg" [ngClass]="isIncorrectLastName===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position" id="lastNameError">{{
                        errorMsgLastName }}</span>
                  </div>
               </div>


               <div class = 'form-group is-empty label-floating' [ngClass] = "errorLengthExceeds ? 'mrClass3' : 'mrClass'">
                  <span for="inputEmail" class="form-label">Email</span>
                  <input type="text" class="form-control" id="inputEmail"
                     [attr.aria-invalid]="invalidEmail===true?'true':'false'" [attr.aria-describedby]="'mailError'"
                     [(ngModel)]="email" name="email" [ngClass]="{'inputError': isErrorEmail || isIncorrectEmail}"
                     (blur)="validateEmailBlur()" (ngModelChange)="redirectToOffice365IfInformaDomain(email)"
                     (input)="validateEmail()">

                  <div class="errorMsg" [ngClass]="(isIncorrectEmail ||  isErrorEmail)===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position" id="mailError">{{ errorMsgEmail
                        }}</span>
                  </div>
               </div>

               <div class="form-group is-empty label-floating mrClass">
                  <span for="country" class="form-label">Country</span>
                  <p id="countryError" aria-live="polite" class="visually-hidden">{{ errorMsgCountry }}</p>
                  <select class="form-control" id="country" aria-labelledby="countryError"
                     [ngClass]="{'inputError':isIncorrectCountry}" (input)="validateCountry()"
                     (blur)="validateCountryBlur()" [(ngModel)]="country" name="country">
                     <option selected value=""></option>
                     <option *ngFor="let country of allCountries" [ngValue]="country.name">{{ country.name }}</option>
                  </select>
                  <div class="errorMsg" [ngClass]="isIncorrectCountry===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position">{{ errorMsgCountry }}</span>
                  </div>
               </div>

               <div class="form-group is-empty label-floating mrClass">
                  <span for="Password" class="form-label">Password</span>
                  <span class="icon-info" tabindex="0" data-toggle="popover" data-trigger="focus"
                     [attr.data-content]="passwordHint"></span>
                  <input [type]=" viewPswd == 'icon-eye-blocked' ? 'password': 'text'" class="form-control"
                     id="inputPassword" [(ngModel)]="password" name="password"
                     [attr.aria-invalid]="isPwdHintError===true?'true':'false'"
                     [ngClass]="{'inputError': isIncorrectPassword}" [attr.aria-describedby]="'passwordError'"
                     (blur)="validatePasswordBlur()" (input)="validatePassword()">
                  <span class="pwd-view-icon"
                     (click)="(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
                     [ngClass]="viewPswd">
                  </span>
                  <div class="errorMsg" [ngClass]="isIncorrectPassword===true?'class1':'class2'">
                     <span class="icon-error"></span><span id="passwordError">{{passwordHint}}</span>
                  </div>
               </div>

               <div class="form-group optin-text">
                  <p id="readPara" class="optin-text"> Taylor & Francis Group use the details you share here to create an account for you.<br />
                     Taylor & Francis Group would also like to use your email address to send you offers and information about related products and services that we think will be of interest to you.<br />
                     You may opt out of receiving these messages at any time by clicking the unsubscribe link found in the footer of our emails.</p>
                  <p class="optin-text">You can find more information in our
                     <a class="underline links" target="_blank" aria-describedby="readPara privacy_policy"
                        [href]="privacyPolicyLink">Privacy Policy</a>
                  </p>
                  <p id="privacy_policy" hidden>Click to find more information about privacy policy.</p>
               </div>

               <div class="form-group terms-check-box">
                  <span class="checkbox pull-left no-margin">
                     <input type="checkbox" id="signup-chk1" class="custom-checkbox  custom-control-input"
                        [(ngModel)]="optOut" name="optOut" />
                     <label class="custom-control-label" for="signup-chk1">If you do not want to receive marketing
                        communication from us, please tick the box</label>
                  </span>
               </div>

               <div class="form-group terms-check-box">
                  <span class="checkbox pull-left no-margin">

                     <input type="checkbox" id="termsCheckBox" class="custom-checkbox  custom-control-input"
                        (ngModelChange)="resetCheckboxFlag()" [(ngModel)]="isChecked" aria-describedby="t&cError"
                        name="isChecked" />
                     <label class="custom-control-label" id="termsAndCondition" for="termsCheckBox"> I agree to the <a
                           class="underline links" target="_blank" [href]="termsOfUseLink"
                           aria-describedby="terms_conditions">Terms & Conditions</a></label>

                     <p id="terms_conditions" hidden>Click to know more about terms and conditions.</p>
                     <p id="t&cError" hidden>{{ errorMsgTerms }}</p>
                  </span>
               </div>

               <div class="errorCheckBox" [ngClass]="isCheckBoxNotClicked===true?'class1':'class2'">{{ errorMsgTerms }}
               </div>
               <div id='hcaptcha' class="form-button-access h-captcha" [attr.data-sitekey]="siteKey"
                  data-callback="onSubmit">
               </div>
               <div *ngIf="!isCaptchaValid" class="h-captcha-err" aria-live="polite">{{hCaptchaErrMsg}}</div>
               <div class="form-group form-button-access text-center">
                  <button (click)="validate()" [disabled]="isSubmitted" [ngClass]="{ 'btnDisabled': isSubmitted}"
                     id="signUpButton" #signupBtn class="themed-button btn btn-primary btn-block btn-lg signUpBtn ">
                     Create Account
                  </button>
               </div>

               <div id="loginLink" class="text-color themed-text">
                  <a class="sign-up text-color themed-text" href="javascript:;" (click)="navigateToLogin()"
                     aria-label=" Already have an account? Login">Or Log in</a>
                  <span class="icon-info" tabindex="0" data-toggle="popover" data-trigger="focus" data-container="body"
                     data-placement="bottom" [attr.data-content]="AccExplaination"></span>
               </div>

            </form>
         </div>
         <div class="description-container" *ngIf="viewDescription">
            <div class="client-description">
               <app-login-description></app-login-description>
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="emailSentScreen" class="emailSentScreen" role="region" aria-label="Email verification">
      <p class="emailSent">Thank you for registering with us</p>
      <div class="emailSent-info">
         <div>We have sent you a verification message to your registered email address <span class="mailId">
               {{email}}</span>. Please check your email.</div>
      </div>
      <a class="redirectLink" href="javascript:;" (click)="resendMail()">Didn't get the email? Resend</a>.
   </div>

</div>