<div class="top-banner" *ngIf="showNotificationScreen">
	<div class="icon-container">
		<span class="icon-check-circle" *ngIf="isSuccess"></span>
		<span class="icon-error" *ngIf="!isSuccess"></span>
	</div>
	<div class="top-banner-container">
		<span>{{message}}</span>
	</div>
	<div>
		<em class="icon-cancel pull-right" (click)="showNotificationScreen=false"></em>
	</div>
</div>
